import React, { useEffect } from "react";
// politiques de confidentialité
function Politique() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="politique bg-white">
      <div className="container-fluid primaire py-5">
        <div className="row text-end pe-5">
          <h1 className="text-white fw-bold my-5 ">
            Politique de confidentialité
          </h1>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="row ">
          <div className="col-12 col-md-10 col-lg-8 mx-auto">
            <p className="mb-5 intro">
              L'Association Générale des Étudiants du Cégep de Trois-Rivières
              (AGECTR) définit la présente Politique de confidentialité afin
              d'indiquer la manière dont elle collecte, utilise, conserve et
              divulgue les informations recueillies auprès des utilisateurs du
              site Web <strong>livres-usages.agectr.com</strong>.
            </p>

            <div className="mb-5">
              <h2 className="fw-semibold">
                Collecte des informations personnelles
              </h2>
              <p>
                Les informations personnelles sont des données qui peuvent être
                utilisées pour identifier ou contacter une seule personne.
                Lorsque vous vous inscrivez à notre site Web, nous vous
                demandons de fournir des informations personnelles telles que
                votre nom, votre adresse e-mail, votre numéro de téléphone etc.
                pour vous permettre de profiter de nos services.
              </p>
              <p>
                Ces informations sont collectées via des formulaires lors de
                votre inscription ou lors de la mise à jour de vos informations.
              </p>
            </div>

            <div className="mb-5">
              <h2 className="fw-semibold">Informations collectées</h2>
              <p>Nous collections les informations suivantes:</p>
              <ul>
                <li>Nom</li>
                <li>Prénom</li>
                <li>Adresse électronique</li>
                <li>Numéro de téléphone</li>
                <li>Numéro matricule</li>
              </ul>
            </div>

            <div className="mb-5">
              <h2 className="fw-semibold">
                Utilisation des informations personnelles
              </h2>
              <p>
                Les informations personnelles collectées sont utilisées dans le
                but de vous offrir nos services. Grâce à ces informations, vous
                pourrez effectuer des transactions et nous serons en mesure de
                traiter de façon efficace toutes autres demandes vous
                concernant.
              </p>
            </div>

            <div className="mb-5">
              <h2 className="fw-semibold">Conservation des données</h2>
              <p>
                Une fois votre inscription complétée, vos informations
                personnelles sont conservées dans notre base de données jusqu'à
                ce que les conditiions suivantes soient réunies:
                <ul>
                  <li>
                    <strong>Vous n'avez aucun livre en vente</strong>
                  </li>
                  <li>
                    <strong>l'AGECTR ne vous doit pas d'argent</strong>
                  </li>
                  <li>
                    <strong>
                      Votre compte est inactif (aucune transaction) depuis une
                      durée de 5 ans
                    </strong>
                  </li>
                </ul>
              </p>
              <p>
                Vous pouvez à tout moment demander la suppression de vos
                informations personnelles en nous contactant.
              </p>
            </div>

            <div className="mb-5">
              <h2 className="fw-semibold">Partage des données</h2>
              <p>
                Nous ne partageons pas vos informations personnelles avec des
                tiers, sauf dans les cas suivants :
              </p>
              <ul>
                <li>avec votre consentement</li>
                <li>pour des raisons de sécurité</li>
                <li>pour se conformer à la loi</li>
              </ul>
            </div>

            <div className="mb-5">
              <h2 className="fw-semibold">Protection des données</h2>
              <p>
                Nous prenons des mesures pour protéger vos informations
                personnelles contre la perte, le vol, l'accès non autorisé, la
                divulgation, la copie, l'utilisation ou la modification non
                autorisée. Cependant, aucune méthode de transmission sur
                Internet ou de stockage électronique n'est sécurisée à 100 %.
              </p>
            </div>
            <div className="mb-5">
              <h2 className="fw-semibold">
                Modifications de la politique de confidentialité
              </h2>
              <p>
                Nous nous réservons le droit de modifier cette politique de
                confidentialité à tout moment. Les modifications prendront effet
                immédiatement après leur publication sur le site Web.
              </p>
            </div>

            <div className="mb-5">
              <h2 className="fw-semibold">Contactez-nous</h2>
              <p>
                Si vous avez des questions ou des préoccupations concernant
                notre politique de confidentialité, veuillez nous contacter au
                coordonnées suivantes:
              </p>

              <p>
                <strong>Nom de la personne :</strong> <br />
                Nathalie Lambert
              </p>

              <p>
                <strong>Fonction :</strong> <br />
                Adjointe administrative
              </p>

              <p>
                <strong>Adresse :</strong> <br />
                Pavillon des Humanités HA-1152 <br />
                3175 Boulevard Laviolette Trois-Rivières, QC
                <br />
                G8Z 1E9
              </p>

              <p>
                <strong>Téléphone :</strong> <br />
                819-376-1721 #2524
              </p>
              <p>
                <strong>E-mail :</strong> <br />
                <a href="mailto:age@cegeptr.qc.ca">age@cegeptr.qc.ca</a>
              </p>
            </div>
          </div>
        </div>
        <div className="row py-5">
          <div className="col-12 text-center">
            <h3>
              Dernière mise à jour: <strong>13 mai 2024</strong>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Politique;
