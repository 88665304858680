export const validerFormConnexion = (u, m) =>
{
    let valid = {
        user:'',
        mdp: '',
        isValid: true
    }

    if(u.trim().length < 2)
    {
        valid.user = 'Entrez un email ou matricule valide'
        valid.isValid = false
    }

    if(m.trim() === '')
    {
        valid.mdp = 'Entrez un mot de passe'
        valid.isValid = false
    }

    return valid
}

