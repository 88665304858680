import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState }  from 'react'
import { connectAPI } from '../helpers/api'
import { SessionData } from '../helpers/SessionData'
import { validerFormUpdateMDP } from '../helpers/validationUpdateMDP'
import { useNavigate } from 'react-router-dom'



export const ModalUpdateMDP = () => {

    const navigate = useNavigate()
    const [ancienMDP, setAncienMDP] = useState("")
    const [nouveauMDP, setNouveauMDP] = useState("")
    const [confirmationMDP, setConfirmationMDP] = useState("")

    const [erreurAncienMDP, seterreurAncienMDP] = useState("")
    const [erreurNouveauMDP, seterreurNouveauMDP] = useState("")
    const [erreurConfirmationMDP, seterreurConfirmationMDP] = useState("")

    const [resultatModif, setResultatModif] = useState("")
    const [couleurResultat, setCouleurResultat] = useState("")

    const [visible, setvisible] = useState("d-none")

    const erreursInitiales = () =>{
        seterreurAncienMDP("")
        seterreurConfirmationMDP("")
        seterreurNouveauMDP("")
    }

    const handleAncienMDPChange=(event)=>{
        setAncienMDP(event.target.value)
    }

    const handleNouveauMDPChange=(event)=>{
        setNouveauMDP(event.target.value)
    }

    const handleConfirmationMDPChange=(event)=>{
        setConfirmationMDP(event.target.value)
    }

    const handleSubmit = event=>{
        event.preventDefault()
        let validation = validerFormUpdateMDP(ancienMDP, nouveauMDP, confirmationMDP)
        
        erreursInitiales()

        if(!validation.isValid)
        {
            seterreurNouveauMDP(validation.nouveau)
            seterreurAncienMDP(validation.ancien)
            seterreurConfirmationMDP(validation.confirmation)
        }
        else{
            setvisible("d-block")
            updateMDP()
        }
    }

    const updateMDP = async () =>{
        let data = new FormData()
        data.append('id_user', SessionData().getIdUser())
        data.append('currentPassword', ancienMDP)
        data.append('newPassword', nouveauMDP)

        const result = await connectAPI("update_password.php", data)

        setvisible("d-none")
        if(result.data == null)
        {
            SessionData().resetUserInfos()
            navigate('/connexion')
        }
        else if(result.data.succes == true)
        {
            setResultatModif("Modifications réussies")
            setCouleurResultat("text-success")
            window.open("http://livres-usages.agectr.com/compte/infos","_self")
        }
        else
        {
            setResultatModif("Une erreur s'est produite. Veuillez réessayer plus tard")
            setCouleurResultat("text-danger")
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                    <div className="col-1 mx-auto text-center">
                        <FontAwesomeIcon icon={faSpinner} spin size="2x" className={visible + " text-center"} />
                    </div>
                </div>
                <div className={"text-center " + couleurResultat}  >
                    <h5>{resultatModif}</h5>
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="idMDP" className="form-label fs-5 ">Ancien mot de passe *</label>
                    <input type="password" className="form-control" onChange={handleAncienMDPChange} required aria-label="Mot de passe" aria-describedby="idMDP" />
                    <div className="text-start form-text col-12 text-danger">{erreurAncienMDP}</div>
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="idMDP" className="form-label fs-5 ">Nouveau mot de passe *</label>
                    <input type="password" className="form-control" onChange={handleNouveauMDPChange} required aria-label="Mot de passe" aria-describedby="idMDP" />
                    <div className="text-start form-text col-12 text-danger">{erreurNouveauMDP}</div>
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="idConfirmation" className="form-label fs-5">Confirmez le mot de passe *</label>
                    <input type="password" className="form-control"  onChange={handleConfirmationMDPChange} required aria-label="Confirmer" aria-describedby="idConfirmation" />
                    <div className="text-start form-text col-12 text-danger">{erreurConfirmationMDP}</div>
                </div>
                <div className=" mb-3 text-center">
                    <button type="submit" id="envoyer" className='btn btn-primary text-white btn-lg col-12 col-md-6'>Modifier</button>
                </div>

            </form>
        </div>
    )
}
