export const validerFormAjoutConcession = (codeBarre, titre, auteur, leprix) =>
{
    let valid = {
        codeBarre:'',
        titre:'',
        auteur:'',
        edition:'',
        editeur:'',
        prix:'',
        photo:'',
        isValid: true
    }
    
    //validation du titre
    if(titre.trim() === "" )
    {
        valid.titre = 'Entrez un titre valide'
        valid.isValid = false
    }

    //validation de l'auteur
    if(auteur.trim() === "" )
    {
        valid.auteur = 'Entrez un auteur valide'
        valid.isValid = false
    }

    
    //validation du code-barre 
    if(codeBarre.trim().includes(" "))
    {
        valid.codeBarre = "Un code-barre ne doit pas contenir d'espace \n"
        valid.isValid = false
    }

    if(codeBarre.trim().length < 13 )
    {
        valid.codeBarre += "Un code-barre a 13 chiffres"
        valid.isValid = false
    }

    //validation du prix
    if(leprix.trim() === "" ){
        valid.prix = 'Entrez une valeur numérique'
        valid.isValid = false
    }
    else
    {
        let p = parseFloat(leprix)

        if(p == NaN)
        {
            valid.prix = 'Entrez une valeur numérique'
            valid.isValid = false
        }
        else
        {
            if(p < 0.50)
            {
                valid.prix = 'Le prix est trop bas'
                valid.isValid = false
            }
            else if(p > 999.99)
            {
                valid.prix = 'Le prix est trop élevé (max 999.99$)'
                valid.isValid = false
            }
        }
    }
    



    //validation de la photo si une valeur est entrée
    /* if( photo.length > 0)
    {
        if( photo[0]. )
        {
            valid.tel = 'format: 819-555-8520'
            valid.isValid = false
        }
    } */

    return valid
}

