import React from "react";
import { useState, useEffect } from "react";
import bgimage from "../img/bg-home.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Defaut } from "../composants/Defaut";
import { Annonce } from "../composants/Annonce";

import ModalAvertissement from "../composants/ModalAvertissement";
import { useNavigate } from "react-router-dom";

export const Accueil = () => {
  const navigate = useNavigate();
  const [livre, setLivre] = useState("");

  const handleLivreChange = (event) => {
    setLivre(event.target.value);
  };

  const handleButtonClick = (event) => {
    if (livre !== "") {
      navigate("/recherche/" + livre);
    }
    event.preventDefault();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="container-fluid bg-home">
        {/* <div className="row  align-items-center text-center mb-4">
                    <div className="mx-auto col-md-6 col-12 mt-3">
                        <h1 className="text-white mb-2 fw-bold text-primary display-1">
                            AGECTR
                        </h1>
                     </div>
                </div> */}
        <div className="row align-items-center text-center h-100">
          <div className="mx-auto col-md-6 col-12">
            <h3 className="text-white fw-bold">Trouvez un livre usagé</h3>
            <form onSubmit={handleButtonClick}>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  onChange={handleLivreChange}
                  placeholder="Entrez un titre ou un ISBN"
                  aria-label="Recherche de livre"
                  aria-describedby="button-addon2"
                />
                <button
                  className="btn btn-lg primaire text-white"
                  type="submitt"
                  id="button-addon2"
                  onClick={handleButtonClick}
                >
                  <FontAwesomeIcon icon={faSearch} className="" size="1x" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Annonce />
      <Defaut />
    </div>
  );
};
