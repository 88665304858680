export const validerFormUpdateInfos = (nom, prenom, email, matricule, tel) =>
{
    let valid = {
        nom:'',
        prenom:'',
        email:'',
        tel:'',
        matricule:'',
        isValid: true
    }

    let emailregex = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
    
    //validation du nom
    if(nom.trim().length < 2)
    {
        valid.nom = 'Entrez un nom valide'
        valid.isValid = false
    }

    //validation du prénom
    if(prenom.trim().length < 2)
    {
        valid.prenom = 'Entrez un prénom valide'
        valid.isValid = false
    }

    //validation de l'email
    if( !email.match(emailregex))
    {
        valid.email = 'Entrez un email valide'
        valid.isValid = false
    }
    else
    {
        if(email.includes('cegeptr.qc.ca'))
        {
            valid.email = 'Les emails du cegep ne sont pas autorisés'
            valid.isValid = false
        }
        
    }


    //validation du matricule si une valeur est entrée
    if( matricule.trim().length > 0)
    {
        if( !matricule.match("[0-9]{7}"))
        {
            valid.matricule = 'Le matricule doit contenir 7 chiffres'
            valid.isValid = false
        }
    }

    //validation du téléhone si une valeur est entrée
    if( tel.trim().length < 10)
    {
        valid.tel = "Entrez un numéro de téléphone valide"
        valid.isValid = false
    }

    return valid
}

