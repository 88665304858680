import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useRef } from 'react'
import InputMask from 'react-input-mask'
import { connectAPI } from '../helpers/api'
import { SessionData } from '../helpers/SessionData'
import { validerFormAjoutConcession } from '../helpers/validationFormAjoutConcession'
import { useNavigate } from 'react-router-dom'

export const AjoutConcession = props => {

    const navigate = useNavigate()
    const photoRef = useRef()

    const [codeBarre, setcodeBarre] = useState("")
    const [idBook, setIdBook] = useState(0)
    const [titre, settitre] = useState("")
    const [auteur, setauteur] = useState("")
    const [editeur, setediteur] = useState("")
    const [edition, setedition] = useState("")
    const [prix, setprix] = useState("")
    const [annotation, setannotation] = useState(false)
    const [codeInternet, setCodeInternet] = useState(false)
    const [photo, setPhoto] = useState(null)
    const [photoFromServer, setPhotoFromServer] = useState(null)
    const [preview, setPreview] = useState("")

    const [erreurCodeBarre, setErreurCodeBarre] = useState("")
    const [erreurTitre, setErreurTitre] = useState("")
    const [erreurAuteur, setErreurAuteur] = useState("")
    const [erreurEditeur, setErreurEditeur] = useState("")
    const [erreurEdition, setErreurEdition] = useState("")
    const [erreurPrix, setErreurPrix] = useState("")
    const [erreurPhoto, setErreurPhoto] = useState("")

    const [resultatAjout, setResultatAjout] = useState("")
    const [couleurResultat, setCouleurResultat] = useState("")

    const [spinnerVisible, setSpinnerVisible] = useState("d-none")
    const [closePreviewVisible, setclosePreviewVisible] = useState("d-none")

    const [inputDisable, setinputDisable] = useState(true)

    const [btAjouterDisabled, setbtAjouterDisabled] = useState(false)
    const [btVerifierDisabled, setbtVerifierDisabled] = useState(false)

    const [fromCodeBarreCheck, setFromCodeBarreCheck] =  useState(false)

    // permet d'afficher le formulaire d'ajout de concession quand l'isbn est trouvé sans la base de données
    const [formVisible, setFormVisible] = useState("d-none")

    const erreursInitiales = () =>{
        setErreurCodeBarre("")
        setErreurTitre("")
        setErreurAuteur("")
        setErreurEditeur("")
        setErreurEdition("")
        setErreurPrix("")
        setErreurPhoto("")
    }

    const handleVerifierClick = ()=>{
        setSpinnerVisible("d-block")
        envoyerCodeBarre()
    }

    const handleCodeBarreChange = (event) =>{
        setcodeBarre(event.target.value)
    }

    const handleTitreChange = (event) =>{
        settitre(event.target.value)
    }

    const handleAuteurChange = (event) =>{
        setauteur(event.target.value)
    }

    const handleEditionChange = (event) =>{
        setedition(event.target.value)
    }

    const handleEditeurChange = (event) =>{
        setediteur(event.target.value)
    }

    const handlePrixChange = (event) =>{
        setprix(event.target.value)
    }

    const handleAnnotationChange = (event) =>{
        setannotation(event.target.checked)
    }

    const handleCodeInternetChange = (event)=>{
        setCodeInternet(event.target.checked)
    }

    const handlePhotoChange = (event) =>{
        
        setPhoto(event.target.files[0])
        try{
            setPreview(URL.createObjectURL(event.target.files[0]))
            setclosePreviewVisible("d-block")
        }
        catch(e)
        {
            resetPreview()
        }
    }

    const resetPreview = () =>{
        setPreview("")
        setPhoto(null)
        setclosePreviewVisible("d-none")
        photoRef.current.value = ""
    }

    const handleSubmit = (event) =>{
        event.preventDefault()

        let tempCodeBarre = codeBarre.replaceAll(" ", "")
        setcodeBarre(tempCodeBarre)

        let validation

        if(fromCodeBarreCheck == true)
            validation = validerFormAjoutConcession(codeBarre, titre, "null", prix)
        else
            validation = validerFormAjoutConcession(codeBarre, titre, auteur, prix)

        erreursInitiales()

        if(!validation.isValid)
        {
            setErreurCodeBarre(validation.codeBarre)
            setErreurTitre(validation.titre)
            setErreurAuteur(validation.auteur)
            setErreurPrix(validation.prix)
        }
        else{
            setSpinnerVisible("d-block")
            ajouter()
            
        }
    }

    const ajouter = async () =>{

        setbtAjouterDisabled(true)
        setbtVerifierDisabled(true)
        let data = {
            'customerPrice': parseFloat(prix),
            'idCustomer': SessionData().getIdUser(),
            'isAnnotated': annotation,
            'haveInternetCode': codeInternet,
            'book':{
                'idBook':idBook,
                'title': titre,
                'author': auteur,
                'barcode': codeBarre,
                'publisher': editeur,
                'edition': edition,
                'urlPhoto': photoFromServer
            }
        }
        
        const result = await connectAPI("add_concession.php", data)

        if(result.data == null)
        {
            setResultatAjout("Erreur lors de l'ajout. Veuillez réessayer")
            setCouleurResultat("red")
            setSpinnerVisible("d-none")    
            setbtAjouterDisabled(false)
            setbtVerifierDisabled(false)        
        }
        else
        {
            setSpinnerVisible("d-none")
            props.alert()
            /*if(photo != null)
            {
                envoyerPhoto(result.data)
            }
            else
            {
                //setResultatAjout("Livre ajouté avec succès")
                //setCouleurResultat("green")
                setSpinnerVisible("d-none")
                props.alert()
                setFromCodeBarreCheck(false)
            }
            //setvisible("d-none")
            */
        }
    }

    const envoyerPhoto = async (concession) =>{
        let data = new FormData()

        data.append('file', photo)
        data.append('idConcession', concession.idConcession)

        const result = await connectAPI("photo_upload.php", data)

        setSpinnerVisible("d-none")

        if(result.data == null)
        {
            SessionData().resetUserInfos()
            navigate('/connexion')
        }
        else if(result.data.succes == true)
        {
            //setResultatAjout("Livre ajouté avec succès")
            //setCouleurResultat("green")
            setSpinnerVisible("d-none")
            props.alert()
        }

    }

    const envoyerCodeBarre = async () =>{
        setbtAjouterDisabled(true)
        setbtVerifierDisabled(true)

        let tempCodeBarre = codeBarre.replaceAll(" ", "")
        setcodeBarre(tempCodeBarre)

        let data = new FormData()

        data.append('barcode', codeBarre)

        const result = await connectAPI("try_auto_fill.php", data)

        setSpinnerVisible("d-none")
        if(result.data == null)
        {
            //setResultatAjout("Aucune correspondance au code-barre. Veuillez entrer les coordonnées du livre.")
            setResultatAjout("Aucune correspondance au code-barre. Veuillez réeesayer.")
            setCouleurResultat("text-danger")
            setbtAjouterDisabled(false)
            setbtVerifierDisabled(false)
            setFormVisible("d-block")
            setinputDisable(false)
            setFromCodeBarreCheck(false)
        }
        else
        {
            if(result.data.disabled == "1")
            {
                setResultatAjout("Cette édition du livre n'est plus acceptée par l'AGECTR.")
                setCouleurResultat("text-danger")
            }
            else
            {
                setResultatAjout("")
                erreursInitiales()
                setFormVisible("d-block")
                setIdBook(result.data.idBook)
                settitre(result.data.title)
                setauteur(result.data.author)
                setediteur(result.data.publisher)
                setedition(result.data.edition)
                setPhotoFromServer(result.data.urlPhoto)
                setinputDisable(true)

                if(result.data.urlPhoto != null)
                {
                    setPreview("http://agectr.com/livreUsage/GlobalAGECTR/upload_photo_book/" + result.data.urlPhoto + ".png")
                    //setclosePreviewVisible("d-block")
                }

                //setinputDisable(true)
                setbtAjouterDisabled(false)

                setFromCodeBarreCheck(true);
            }
        }
    }

    
    return (
        <div>
            <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                    <div className="col-1 mx-auto text-center">
                        <FontAwesomeIcon icon={faSpinner} spin size="2x" className={spinnerVisible + " text-center"} />
                    </div>
                </div>
                <div className={"text-center " + couleurResultat}  >
                    <h5>{resultatAjout}</h5>
                </div>
                <div className="row g-0">
                    <label htmlFor="codeBarre" className="form-label fs-5 ">Code barres ou ISBN *</label>
                    <div className="col-8 mb-3">
                        {/* <input type="text" className="form-control" onChange={handleCodeBarreChange} required aria-label="Code-barre" aria-describedby="codeBarre" maxLength={"13"} disabled={inputDisable} /> */}
                        <InputMask mask="9999999999999" maskChar=" " className="form-control" onChange={handleCodeBarreChange} value={codeBarre} required  />
                        <p className="text-start form-text col-12 m-0">Uniquement des chiffres sans espaces</p>
                        <div className="text-start form-text col-12 text-danger">{erreurCodeBarre}</div>
                    </div>
                    <div className="col-4 text-center" >
                        <button className="btn btn-success" disabled={btVerifierDisabled} onClick={handleVerifierClick}>Valider</button>
                    </div>
                </div>
                {/* Zone de fomulaire */}
                <div className={formVisible}>
                    <div className="col-12 mb-3">
                        <label htmlFor="titre" className="form-label fs-5 ">Titre *</label>
                        <input type="text" className="form-control" onChange={handleTitreChange} value={titre} required aria-label="Titre" aria-describedby="titre"  disabled={inputDisable}/>
                        <div className="text-start form-text col-12 text-danger">{erreurTitre}</div>
                    </div>
                    <div className="col-12 mb-3">
                        <label htmlFor="auteur" className="form-label fs-5 ">Auteur</label>
                        <input type="text" className="form-control" onChange={handleAuteurChange} value={auteur} required aria-label="Auteur" aria-describedby="auteur" disabled={inputDisable} />
                        <div className="text-start form-text col-12 text-danger">{erreurAuteur}</div>
                    </div>
                    <div className="col-12 mb-3">
                        <label htmlFor="edition" className="form-label fs-5 ">Édition</label>
                        <input type="text" className="form-control" onChange={handleEditionChange} value={edition} required aria-label="Édition" aria-describedby="edition" disabled={inputDisable} />
                        <div className="text-start form-text col-12 text-danger">{erreurEdition}</div>
                    </div>
                    <div className="col-12 mb-3">
                        <label htmlFor="editeur" className="form-label fs-5 ">Éditeur</label>
                        <input type="text" className="form-control" onChange={handleEditeurChange} value={editeur} required aria-label="Éditeur" aria-describedby="editeur" disabled={inputDisable} />
                        <div className="text-start form-text col-12 text-danger">{erreurEditeur}</div>
                    </div>
                    <div className="col-12 mb-3">
                        <label htmlFor="prix" className="form-label fs-5 ">Prix *</label>
                        <input type="text" className="form-control" onChange={handlePrixChange} required aria-label="Prix" aria-describedby="prix" maxLength={"6"} />
                        <div className="text-start form-text col-12 text-danger">{erreurPrix}</div>
                    </div>
                    <div className="row mb-4 mt-3">
                        <div className="col-2 text-end align-self-center">
                            <input className="form-check-input" type="checkbox" onClick={handleAnnotationChange} />
                        </div>
                        <div className="col-10 ">
                            <label htmlFor="idConditions" className="form-label fs-5">Cochez si vous avez écrit dans le livre</label>
                        </div>
                    </div>
                    <div className="row mb-4 mt-3">
                        <div className="col-2 text-end align-self-center">
                            <input className="form-check-input" type="checkbox" onClick={handleCodeInternetChange} />
                        </div>
                        <div className="col-10 ">
                            <label htmlFor="idCodeInternet" className="form-label fs-5">Cochez si le livre contient un code internet</label>
                        </div>
                    </div>
                    {/* <div className="col-12 mb-3">
                        <label htmlFor="photo" className="form-label fs-5 ">Ajoutez une photo</label>
                        <input type="file" className="form-control" onChange={handlePhotoChange} ref={photoRef} aria-label="Prix" aria-describedby="photo" />
                        <div className="text-start form-text col-12 text-danger">{erreurPhoto}</div>
                    </div>
                    <div className={closePreviewVisible + " col-12 text-end"}>
                        <button type="button" className="btn-close" onClick={resetPreview} aria-label="Close"></button>
                    </div> */}
                    <div className="col-8 mx-auto mb-3">
                        <img src={preview} className="img-fluid d-block mx-auto"/>
                    </div>

                    <div className=" mb-3 text-center">
                        <button type="submit" id="envoyer" disabled={btAjouterDisabled} className='btn btn-primary text-white btn-lg col-12 col-md-6'>Ajouter</button>
                    </div>

                </div>

            </form>
        </div>
    )
}
