import React, { useEffect, useState } from 'react'
import { getLivreChoisi } from "../helpers/livreChoisi"
import { connectAPI } from "../helpers/api"
import { Exemplaire } from '../composants/Exemplaire'
import  ModalAvertissement  from '../composants/ModalAvertissement'
import { Modal } from 'react-bootstrap'
import { SessionData } from '../helpers/SessionData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'


export const Exemplaires = () =>{
    window.scrollTo(0, 0)
    
    const navigate = useNavigate();

    const livre = getLivreChoisi()
    
    const [listeLivres, setlisteLivres] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [isModalAvertissementOpen, setIsModalAvertissementOpen] = useState(false)

    const [dis, setDisabled] = useState(true)

    const [nbExemplaires, setnbExemplaires] = useState(0)

    const [titreExemplaire, setTitreExemplaire] = useState("")
    const [auteurExemplaire, setAuteurExemplaire] = useState("")
    const [editionExemplaire, setEditionExemplaire] = useState("")
    const [editeurExemplaire, setEditeurExemplaire] = useState("")
    const [urlPhotoExemplaire, setUrlPhotoExemplaire] = useState("")
    const [barcodeExemplaire, setBarcodeExemplaire] = useState("")
    const [annotationExemplaire, setAnnotationExemplaire] = useState("")
    const [prixExemplaire, setPrixExemplaire] = useState("")
    const [idConcession, setIdConcession] = useState("")
    const [haveInternetCode, setHaveInternetCode] = useState("")

    const [btVisible, setbtVisible] = useState("d-block")
    const [iconVisible, seticonVisible] = useState("d-none")
    const [texteVisible, settexteVisible] = useState("d-none")
    const [spinnerVisible, setspinnerVisible] = useState("d-none")
    

    const openModal = () => {
        setIsOpen(true);
    }

    const handleModalAvertissementClose=()=>{
        setIsModalAvertissementOpen(false)
    }
    
    const hideModal = () => {
        setIsOpen(false);
        if(iconVisible == "d-block")
        {
            seticonVisible("d-none")
            setbtVisible("d-block")
            setIsModalAvertissementOpen(true)
        }
    }

    const getExemplaireChoisi = (i) =>{
        setTitreExemplaire(listeLivres[i].book.title) 
        setAuteurExemplaire(listeLivres[i].book.author)
        setEditeurExemplaire(listeLivres[i].book.publisher)
        setEditionExemplaire(listeLivres[i].book.edition)
        setUrlPhotoExemplaire(listeLivres[i].book.urlPhoto)
        setBarcodeExemplaire(listeLivres[i].barcode)
        setPrixExemplaire(listeLivres[i].sellingPrice)
        setIdConcession(listeLivres[i].idConcession)

        if(listeLivres[i].isAnnotated == 1)
            setAnnotationExemplaire("avec annotations")
        else
            setAnnotationExemplaire("")

        if(listeLivres[i].haveInternetCode == 1)
            setHaveInternetCode("avec code internet")
        else
            setHaveInternetCode("")


        openModal()
    }

    const getExemplaires = async () =>{
        let data = new FormData()
        data.append('idBook', parseInt(livre.idBook))
        
        const result = await connectAPI("get_detail_search.php", data)

        setlisteLivres(result.data)
    }

    const reserver = async () => {
        setspinnerVisible("d-block")
        let data = new FormData()
        data.append('idConcession', idConcession)
        data.append('id_user', SessionData().getIdUser())
        
        const result = await connectAPI("reserve_concession.php", data)

        setspinnerVisible("d-none")
        if(result.data == null)
        {
            SessionData().resetUserInfos()
            navigate('/connexion')
        }
        else if(result.data.succes === true)
        {
            setbtVisible("d-none")
            seticonVisible("d-block")
            
        }
        else
        {
            alert("La réservation n'a pas pu se faire. Veuillez réessayer.")
        }
    }

    
    useEffect(()=>{
        
        getExemplaires()
        setDisabled(!SessionData().getEtatSession())

        if(SessionData().getEtatSession() == true)
            settexteVisible("d-none")
        else
            settexteVisible("d-block")
        
    }, [iconVisible])

    
    
    return (
        <div>
            <div className="container-fluid p-2 primaire text-white">
                <div className="row p-4">
                    <div className="col-5 text-end">
                        <img src={"http://agectr.com/livreUsage/GlobalAGECTR/upload_photo_book/"+ livre.urlPhoto+".png"} className="img-fluid"  id={livre.idBook}/>
                    </div>
                    <div className="col-7 p-2">
                        <h5 className="fst-italic">{livre.codebarre}</h5>
                        <h4>{livre.titre}</h4>
                        <h6>{livre.auteur}</h6>
                        <h6>{livre.edition}</h6>
                        <h6>{livre.editeur}</h6>
                    </div>

                </div>
            </div>
            <div className="container">
                <div className="row my-4">
                    <h4>
                        Nombre d'exemplaire(s): {listeLivres.length}
                    </h4>
                </div>
                <div className="row row-cols-2 row-cols-md-4 p-3" >

                    {
                        listeLivres.map( (unExemplaire, index) => (
                            <Exemplaire key={unExemplaire.idConcession}  ex={unExemplaire} getExemplaireChoisi={getExemplaireChoisi} position={index} />           
                            )
                        )
                    }
                </div>
            </div>      

            <Modal show={isOpen} onHide={hideModal}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-5">
                            <img src={"http://agectr.com/livreUsage/GlobalAGECTR/upload_photo_book/"+ urlPhotoExemplaire +".png"} className="img-fluid" />
                        </div>
                        <div className="col-7">
                            <h4>{titreExemplaire}</h4>
                            <h6>{auteurExemplaire}</h6>
                            <h6>{editionExemplaire}</h6>
                            <h6>{editeurExemplaire}</h6>
                            <div className="mt-4 text-center texte-primaire display-6">
                                {prixExemplaire + "$"}
                            </div>
                            <small className="text-center">{annotationExemplaire}</small> <small className="text-center">{haveInternetCode}</small>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn " onClick={hideModal}  >Fermer</button>
                    <button className={btVisible + " btn btn-success"} disabled={dis}  onClick={reserver} >Réserver</button>
                    <FontAwesomeIcon icon={faCheck} size="2x" className={iconVisible + " text-success"}/>
                    <FontAwesomeIcon icon={faSpinner} spin className={spinnerVisible} />
                    <br />
                    <div className="row text-center">
                        <small className={texteVisible}>Connectez-vous pour réserver le livre</small>
                    </div>
                </Modal.Footer>
            </Modal>

            <ModalAvertissement isModalAvertissementOpen={isModalAvertissementOpen} handleModalAvertissementClose={handleModalAvertissementClose}/>

        </div>
    )
}
