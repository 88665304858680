import React, { useState, useEffect } from 'react'
import { connectAPI } from '../helpers/api'
import { LivresAccueil } from './LivresAccueil'

export const ResRecherche = (props) =>{

    const [listeLivres, setlisteLivres] = useState([])
    
    const getExemplaires = async () =>{
        if(props.livreRecherche != "")
        {
            let data = new FormData()
            data.append('search', props.livreRecherche)
            
            const result = await connectAPI("get_groupresult.php", data)

            setlisteLivres(result.data)
        }
    }

    useEffect(()=>{
        getExemplaires()
    }, [props])

    return (
        <div>
            <div className="container mt-5">
                <div className="row">
                    <h1 className="">Résultats pour "{props.livreRecherche}"</h1> 
                    {/* : {listeLivres.length} */}
                    
                </div>
            </div>
            <div className="container p-3">
                <div className="row row-cols-2 row-cols-md-4 p-3">
                    {
                        listeLivres.length > 0 ?
                        listeLivres.map( unlivre => (
                            <LivresAccueil key={unlivre.book.idBook} nb={unlivre.amount}  livre={unlivre.book} />           
                            )
                        ) 
                        : <h3 className="text-center">Le livre recherché n'est pas en inventaire présentement.</h3>
                    }
                </div>
            </div>
        </div>
    )
}