import React from 'react'

export const Exemplaire = (props) =>{
    
    const handleClicExemplaire = () =>{
        props.getExemplaireChoisi(props.position)
    }

    return(
        <div>
            <div className="col p-2 mb-2" >
                <div className="row">
                    <div className="col-8 mx-auto text-center img-hover" id={props.ex.idConcession}>
                        <img src={"http://agectr.com/livreUsage/GlobalAGECTR/upload_photo_book/"+props.ex.book.urlPhoto+".png"} className="img-fluid" id={props.ex.idConcession} onClick={handleClicExemplaire}/>
                    </div>
                </div>
                <div className="row py-1">
                    <div className="col-8 mx-auto text-center fw-bold texte-primaire ">
                        <h4>{props.ex.sellingPrice + "$"}</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}