import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SessionData } from '../helpers/SessionData'
import { SessionExpiree } from './SessionExpiree'
import { connectAPI } from '../helpers/api'
import { Modal } from 'react-bootstrap'
import { MesReservations } from '../composants/MesReservations'
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

export const Reservations = () => {
    window.scrollTo(0, 0)
    const navigate = useNavigate();

    const [listeLivres, setlisteLivres] = useState([])
    const [isOpen, setIsOpen] = useState(false)

    const idUser = SessionData().getIdUser()

    const [titreExemplaire, setTitreExemplaire] = useState("")
    const [auteurExemplaire, setAuteurExemplaire] = useState("")
    const [editionExemplaire, setEditionExemplaire] = useState("")
    const [editeurExemplaire, setEditeurExemplaire] = useState("")
    const [urlPhotoExemplaire, setUrlPhotoExemplaire] = useState("")
    const [barcodeExemplaire, setBarcodeExemplaire] = useState("")
    const [annotationExemplaire, setAnnotationExemplaire] = useState("")
    const [prixExemplaire, setPrixExemplaire] = useState("")
    const [idConcession, setIdConcession] = useState("")

    const [btVisible, setbtVisible] = useState("d-block")
    const [iconVisible, seticonVisible] = useState("d-none")
    const [spinnerVisible, setspinnerVisible] = useState("d-none")

    const openModal = () =>{
        setIsOpen(true)
    }

    const hideModal = () => {
        setIsOpen(false);
        if(iconVisible == "d-block")
        {
            seticonVisible("d-none")
            setbtVisible("d-block")
        }
    }

    const getReservations = async () =>{
        let data = new FormData()
        data.append('id_user', parseInt(idUser))
        
        const result = await connectAPI("fetch_my_reservations.php", data)

        if(result.data == null)
        {
            SessionData().resetUserInfos()
            navigate('/connexion')
        }
        else 
            setlisteLivres(result.data)
    }

    const getExemplaireChoisi = (i) =>{

        setTitreExemplaire(listeLivres[i].book.title) 
        setAuteurExemplaire(listeLivres[i].book.author)
        setEditeurExemplaire(listeLivres[i].book.publisher)
        setEditionExemplaire(listeLivres[i].book.edition)
        setUrlPhotoExemplaire(listeLivres[i].book.urlPhoto)
        setBarcodeExemplaire(listeLivres[i].barcode)
        setPrixExemplaire(listeLivres[i].sellingPrice)
        setIdConcession(listeLivres[i].idConcession)

        if(listeLivres[i].isAnnotated == 1)
            setAnnotationExemplaire("avec annotations")
        else
            setAnnotationExemplaire("")

        openModal()

    }

    const annulerReservation = async () => {
        setspinnerVisible("d-block")
        let data = new FormData()
        data.append('idConcession', idConcession)
        
        const result = await connectAPI("unreserve_concession.php", data)

        setspinnerVisible("d-none")

        if(result.data == null)
        {
            SessionData().resetUserInfos()
            navigate('/connexion')
        }
        else if(result.data.succes == true)
        {
            setbtVisible("d-none")
            seticonVisible("d-block")
        }
        else
        {
            alert("La réservation n'a pas pu se faire. Veuillez réessayer.")
        }
    }

    useEffect(()=>{
        getReservations()
    }, [iconVisible])


    if(SessionData().getEtatSession() == false)
    {
        //permet de changer l'url et aller vers le composant de connexion
        //window.history.pushState({}, '', '/connexion')
        //navigate('/connexion')
        return (
            <SessionExpiree />
        )
    }
    else
    {
        return (
            <div>
                <div className="container p-4">
                    <div className="row mb-3">
                        <div className="col-11 ">
                            <h3 className="moyen-texte">Mes reservations ({listeLivres.length})</h3>
                        </div>
                    </div>
                    <div>
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3">
                            {
                                listeLivres.map( (unExemplaire, index) => (
                                    <MesReservations key={unExemplaire.idConcession}  ex={unExemplaire} getExemplaireChoisi={getExemplaireChoisi} position={index} />           
                                    )
                                )
                            }
                        </div>
                       
                    </div>

                </div>
                <Modal show={isOpen} onHide={hideModal}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-5">
                            <img src={"http://agectr.com/livreUsage/GlobalAGECTR/upload_photo_book/"+ urlPhotoExemplaire +".png"} className="img-fluid" />
                        </div>
                        <div className="col-7">
                            <h4>{titreExemplaire}</h4>
                            <h6>{auteurExemplaire}</h6>
                            <h6>{editionExemplaire}</h6>
                            <h6>{editeurExemplaire}</h6>
                            <div className="mt-4 text-center texte-primaire display-6">
                                {prixExemplaire + "$"}
                            </div>
                            <small className="text-center">{annotationExemplaire}</small>
                            
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn " onClick={hideModal}  >Fermer</button>
                    <button className={btVisible + " btn btn-success"}   onClick={annulerReservation} >Annuler la réservation </button> 
                    <FontAwesomeIcon icon={faCheck} size="2x" className={iconVisible + " text-success"}/>
                    <FontAwesomeIcon icon={faSpinner} spin className={spinnerVisible} />
                </Modal.Footer>
            </Modal>
            </div>
        )
    }
}

