import React, {useEffect} from 'react'
import isbn from "../img/isbn.jpg"
import { Link } from 'react-router-dom'

export const FAQ = () =>{
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <div className="container-fluid text-end parallax bg-faq">
                <div className="row align-items-center h-100 pe-sm-3">
                    <h1 className="fs-md-4 texte-primaire titre">Foire aux questions</h1>
                </div>
            </div>
            <div className="container-fluid p-5">
                <div className="row text-center mb-5">
                    <p className="fs-4">
                        Une liste de questions fréquemment posées.<br />
                        Si toutefois vous n'avez pas trouvé la réponse à votre question, veuillez <Link to="/contact" className="text-decoration-none">nous contacter</Link>
                    </p>
                </div>

                <div className="row p-3 bg-white shadow">
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading1">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question1" aria-expanded="false" aria-controls="question1">
                                <h4>Quelles sont vos heures d’ouverture?</h4>
                            </button>
                            </h2>
                            <div id="question1" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Pour consulter nos heures d'ouvertures cliquez  <Link to="/contact">ici</Link>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading2">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question2" aria-expanded="false" aria-controls="question2">
                                <h4>Comment puis-je payer?</h4>
                            </button>
                            </h2>
                            <div id="question2" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Nous acceptons les paiements au comptant et par débit.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading3">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question3" aria-expanded="false" aria-controls="question3">
                                <h4>Prenez-vous tous les genres de livres?</h4>
                            </button>
                            </h2>
                            <div id="question3" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>Nous mettons en consignation les livres en lien avec les cours dispensés au Cégep de Trois-Rivières, autant les romans que les manuels.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading4">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question4" aria-expanded="false" aria-controls="question4">
                                <h4>Est-ce que je peux donner mes livres à l’AGECTR?</h4>
                            </button>
                            </h2>
                            <div id="question4" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Oui, l’AGECTR accepte les dons de livres pour les remettre gratuitement à des étudiants.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading5">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question5" aria-expanded="false" aria-controls="question5">
                                <h4>Est-ce que j’ai vendu des livres?</h4>
                            </button>
                            </h2>
                            <div id="question5" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Le système vous envoie un courriel lorsqu’un livre se vend, mais vous pouvez en tout temps le valider sur votre compte ou au local HA-1152
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading6">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question6" aria-expanded="false" aria-controls="question6">
                                <h4>Quand puis-je récupérer mon argent suite à la vente d’un livre?</h4>
                            </button>
                            </h2>
                            <div id="question6" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Dès que vous recevez le courriel confirmant la vente d’un livre, vous pouvez passer au local HA-1152 pour obtenir un remboursement. Nous vous rembourserons en comptant ou par chèque.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading7">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question7" aria-expanded="false" aria-controls="question7">
                                <h4>Que se passe-t-il si je ne renouvelle pas mes livres?</h4>
                            </button>
                            </h2>
                            <div id="question7" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Ils deviennent la propriété de l’AGECTR. C’est votre responsabilité de faire le renouvellement.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading8">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question8" aria-expanded="false" aria-controls="question8">
                                <h4>À quel prix je peux vendre mes livres?</h4>
                            </button>
                            </h2>
                            <div id="question8" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    C‘est vous qui déterminez à quel prix vous voulez vendre votre livre. Nous proposons 50% du prix du livre neuf.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading9">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question9" aria-expanded="false" aria-controls="question9">
                                <h4>Combien de temps puis-je laisser mes livres en consignation?</h4>
                            </button>
                            </h2>
                            <div id="question9" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    La consignation a une durée d’un an pouvant être renouvelée avant la date d’échéance. Vous pouvez valider cette date dans votre compte ou au local HA-1152 de l’AGECTR.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading10">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question10" aria-expanded="false" aria-controls="question10">
                                <h4>Est-ce que je peux retourner un livre que j’ai acheté?</h4>
                            </button>
                            </h2>
                            <div id="question10" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Les retours ne sont pas acceptés mais vous pouvez le remettre en consignation pour le vendre.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading11">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question11" aria-expanded="false" aria-controls="question11">
                                <h4>Qu'est-ce qu'un ISBN ?</h4>
                            </button>
                            </h2>
                            <div id="question11" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    C'est le numéro de code à barres qui se situe normalement à l'arrière du livre ou dans les premières pages avant le texte principal.
                                    <div className="row">
                                        <div className="col-4 mx-auto">
                                            <img src={isbn} alt="ISBN" className="img-fluid"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading12">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question12" aria-expanded="false" aria-controls="question12">
                                    <h4>Après avoir enregistré mes livres en ligne, à quel moment est-ce que je peux aller déposer mes livres ?</h4>
                                </button>
                            </h2>
                            <div id="question12" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                        Référer aux <Link to="/contact">heures d'ouverture</Link>.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading13">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question13" aria-expanded="false" aria-controls="question13">
                                    <h4>Puis-je modifier le prix de mon livre une fois mis en vente ?</h4>
                                </button>
                            </h2>
                            <div id="question13" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Oui en vous connectant à votre compte, puis en vous rendant à la section "Mes livres". Vous pourrez alors sélectionner le livre et changer son prix. 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}