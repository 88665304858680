import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SessionData } from '../helpers/SessionData'
import { SessionExpiree } from './SessionExpiree'
import { connectAPI } from '../helpers/api'
import { Modal } from 'react-bootstrap'
import { MesReservations } from '../composants/MesReservations'
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { LigneHistorique } from '../composants/LigneHistorique'
import { useNavigate } from 'react-router-dom'

export const Historique = () => {
    window.scrollTo(0, 0)
    const navigate = useNavigate();

    const [listeLivres, setlisteLivres] = useState([])
    const [isOpen, setIsOpen] = useState(false)

    const idUser = SessionData().getIdUser()
/*
    const [titreExemplaire, setTitreExemplaire] = useState("")
    const [auteurExemplaire, setAuteurExemplaire] = useState("")
    const [editionExemplaire, setEditionExemplaire] = useState("")
    const [editeurExemplaire, setEditeurExemplaire] = useState("")
    const [urlPhotoExemplaire, setUrlPhotoExemplaire] = useState("")
    const [barcodeExemplaire, setBarcodeExemplaire] = useState("")
    const [annotationExemplaire, setAnnotationExemplaire] = useState("")
    const [prixExemplaire, setPrixExemplaire] = useState("")
    const [idConcession, setIdConcession] = useState("")

    const [btVisible, setbtVisible] = useState("d-block")
    const [iconVisible, seticonVisible] = useState("d-none")
    const [spinnerVisible, setspinnerVisible] = useState("d-none")

    const openModal = () =>{
        setIsOpen(true)
    }

    const hideModal = () => {
        setIsOpen(false);
        if(iconVisible == "d-block")
        {
            seticonVisible("d-none")
            setbtVisible("d-block")
        }
    }*/

    const getHistorique = async () =>{
        let data = new FormData()
        data.append('id_user', parseInt(idUser))
        
        const result = await connectAPI("fetch_my_history.php", data)
        if(result.data == null)
        {
            SessionData().resetUserInfos()
            navigate('/connexion')
        }
        else
            setlisteLivres(result.data)
    }


    useEffect(()=>{
        getHistorique()
    }, [])


    if(SessionData().getEtatSession() == false)
    {
        //permet de changer l'url et aller vers le composant de connexion
        //window.history.pushState({}, '', '/connexion')
        //navigate('/connexion')
        navigate('/connexion')

        return null
    }
    else
    {
        return (
            <div>
                <div className="container p-4">
                    <div className="row mb-3">
                        <div className="col-11 ">
                            <h3 className="moyen-texte">Mon historique ({listeLivres.length})</h3>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <table class="table table-striped">
                                <thead class="table-info">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Titre</th>
                                        <th scope="col">Prix</th>
                                        <th scope="col">Statut</th>
                                        <th scope="col">Code internet</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    listeLivres.map((ligne, index) => (
                                        <LigneHistorique key={index}  ligne={ligne} index={index}  />           
                                        )
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                       
                    </div>

                </div>
            </div>
        )
    }
}

