import React from 'react'

function Horaire({jour, debut, fin}) {
  return (
    <div className='row border-bottom border-info py-1'>
        <div className='col-2'>
            <h4>{jour}</h4>
        </div>
        <div className='col-10 justify-content-end d-flex flex-wrap'>
            <h4 className='me-4'>{debut}</h4>
            <h4>{fin}</h4>
        </div>
    </div>
  )
}

export default Horaire