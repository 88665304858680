import React, { useState } from 'react'
import logo from '../img/logo_age_600px.png'
import { SessionData } from '../helpers/SessionData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import {Link, NavLink, useNavigate } from 'react-router-dom'

export const Navbar = () => {
    let btConnexion

    const navigate = useNavigate()
    const [ouvert, setouvert] = useState(false)

    const deconnexion = event =>{
        event.preventDefault()
        if(window.confirm("Voulez-vous vous déconnecter ?") == true)
        {
            SessionData().resetUserInfos()

            navigate('/connexion')
        }
    }

    if(!SessionData().getEtatSession())
    {
        btConnexion = <>
            <Link to="/connexion" className="nav-link " >
                <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Connexion</span>
            </Link>
        </>
    }
    else
    {
        btConnexion = <div>
            <Link to="#" className="nav-link dropdown-toggle"  id="dropdownMenuButton" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Mon compte
            </Link>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-dark" aria-labelledby="dropdownMenuButton">
                <Link to="/compte/infos" className="dropdown-item" ><li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Mes informations</li></Link>
                <Link to="/compte/livres" className="dropdown-item" ><li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Mes livres</li></Link>
                <Link to="/compte/reservations" className="dropdown-item" ><li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Mes reservations</li></Link>
                <Link to="/compte/historique" className="dropdown-item" ><li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Mon historique</li></Link>
                <li><hr class="dropdown-divider" /></li>
                <li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" onClick={deconnexion}>
                    <Link to="" className="dropdown-item bg-danger text-white" >
                        <FontAwesomeIcon icon={faSignOutAlt} size="1x" /> Se déconnecter
                    </Link>
                </li>
            </ul>
        </div>
    } 

    return (
        
            <nav className="navbar navbar-expand-sm navbar-light bg-white texte-primaire sticky-top p-3 fs-4" >
                <Link to="/" className="navbar-brand"  >
                    <img src={logo} alt="logo" className="img-fluid" width={'75'} height={'77'}/>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#liste">
                    <span className="navbar-toggler-icon"></span>
                </button>
                
                <div className="collapse navbar-collapse justify-content-end texte-primaire" id="liste"  >
                    <ul className="navbar-nav">
                        <Link to="/" className="nav-link" >
                            <li className="nav-item mx-3 " data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Accueil</li>
                        </Link>
                        <Link to="/contact" className="nav-link " >
                            <li className="nav-item mx-3" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Contact </li>
                        </Link>
                        <Link to="/FAQ" className="nav-link">
                            <li className="nav-item mx-3" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">FAQ</li>
                        </Link>
                        <li className="nav-item dropdown mx-3 d-inline" >
                             {btConnexion}
                        </li>

                    </ul>
                </div>
            </nav>

            
        
    )
}