import React, { useEffect, useState, useContext } from 'react'
import { MesLivres } from '../composants/MesLivres'
import { connectAPI } from "../helpers/api"
import { Exemplaire } from '../composants/Exemplaire'
import { SessionData } from '../helpers/SessionData'
import { Alert, Modal } from 'react-bootstrap'
import { ModalBody } from 'react-bootstrap'
import { AjoutConcession } from '../composants/AjoutConcession'
import { SessionExpiree } from './SessionExpiree'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'


export const Livres = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
        getLivres()
    }, [])

    const [listeLivres, setlisteLivres] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [mainSpinnerVisible, setmainSpinnerVisible] = useState("d-none")
    const [showAlert, setshowAlert] = useState(false)


    const idUser = SessionData().getIdUser()

    const openModal = () =>{
        setIsOpen(true)
    }

    const hideModal = () => {
        setIsOpen(false);
    }
    
    const getLivres = async () =>{
        setmainSpinnerVisible("d-block")
        let data = new FormData()
        data.append('id_user', parseInt(idUser))
        
        const result = await connectAPI("fetch_my_concession.php", data)

        setlisteLivres(result.data)
        setmainSpinnerVisible("d-none")
        //console.log(result.data);
    }

    const renouvelerConcessions = async () =>{
        setmainSpinnerVisible("d-block")
        let data = new FormData()
        data.append('idCustomer', parseInt(idUser))
        
        const result = await connectAPI("renew_concession_all.php", data)

        setmainSpinnerVisible("d-none")
        
        if(result.data == null)
        {
            SessionData().resetUserInfos()
            navigate('/connexion')
        }
        else if(result.data.succes == true)
            alert("Renouvellement des livres effectué avec succès")
        else
            alert("Un problème est survenu lors du renouvellement. Veuillez réessayer")

    }

    const getExemplaireChoisi = (i) =>{
        //console.log(listeLivres[i]);
        
        sessionStorage.setItem("monlivre_idBook", listeLivres[i].book.idBook) 
        sessionStorage.setItem("monlivre_titre", listeLivres[i].book.title) 
        sessionStorage.setItem("monlivre_auteur", listeLivres[i].book.author)
        sessionStorage.setItem("monlivre_editeur", listeLivres[i].book.publisher)
        sessionStorage.setItem("monlivre_edition", listeLivres[i].book.edition)
        sessionStorage.setItem("monlivre_urlPhoto", listeLivres[i].book.urlPhoto)
        sessionStorage.setItem("monlivre_urlPhoto_statut", listeLivres[i].urlPhoto)
        sessionStorage.setItem("monlivre_barcode", listeLivres[i].book.barcode)
        sessionStorage.setItem("monlivre_prix", listeLivres[i].customerPrice)
        sessionStorage.setItem("monlivre_statut", listeLivres[i].state)
        sessionStorage.setItem("monlivre_expiration", listeLivres[i].expireDate)
        sessionStorage.setItem("monlivre_annotations", listeLivres[i].isAnnotated)
        sessionStorage.setItem("monlivre_haveInternetCode", listeLivres[i].haveInternetCode)
        sessionStorage.setItem("monlivre_idConcession", listeLivres[i].idConcession)
        sessionStorage.setItem("monlivre_refus", listeLivres[i].refuseReason)

        navigate("/compte/monlivre")

    }

    const afficherAlert=()=>{
        hideModal()
        getLivres()
        setshowAlert(true)
        window.setTimeout(() => setshowAlert(false),3000)
    }

    if(SessionData().getEtatSession() == false)
    {
        //permet de changer l'url et aller vers le composant de connexion
        //window.history.pushState({}, '', '/connexion')
        //navigate('/connexion')
        navigate('/connexion')

        return null
    }
    else
    {
        return (
            <div>
                <div className="container p-4">
                    <Alert variant="success" show={showAlert}>
                        Livre ajouté avec succès
                    </Alert>
                    <div className="row mb-5">
                        <div className="col-6 ">
                            <h3 className="moyen-texte">Mes livres ({listeLivres.length})</h3>
                        </div>
                        <div className="col-6 text-end">
                            <div class="dropdown">
                                <button class="btn btn-success dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    Actions
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li><a class="dropdown-item" href="#" onClick={openModal}>Ajouter un livre à vendre</a></li>
                                    <li><a class="dropdown-item" href="#" onClick={renouvelerConcessions}>Renouveler toutes les concessions</a></li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 text-center">
                            <h4 className={mainSpinnerVisible}><FontAwesomeIcon icon={faSpinner} spin size="2x"  /> </h4>
                            
                        </div>
                    </div>
    
                    <div>
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3">
                            {
                                listeLivres.map( (unExemplaire, index) => (
                                    <MesLivres key={unExemplaire.idConcession}  ex={unExemplaire} getExemplaireChoisi={getExemplaireChoisi} position={index} />           
                                    )
                                )
                            }
                        </div>
                       
                    </div>
                </div>
    
                <Modal show={isOpen} onHide={hideModal}>
                    <Modal.Header>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="text-end">
                                    <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="text-center">
                                    <h3>Ajout de livre</h3>
                                </div>
                            </div>
                        </div>
                        
                    </Modal.Header>
                    <ModalBody>
                        <AjoutConcession alert={afficherAlert} />
                    </ModalBody>
                </Modal>
            </div>
        )
    }

  
    
}

