import { faAt, faHashtag, faPen, faPhone, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'
import React, { useState, useEffect } from 'react'
import { ModalBody } from 'react-bootstrap'
import { SessionData } from '../helpers/SessionData'
import { ModalUpdateMDP } from './ModalUpdateMDP'
import { ModalUpdateInfos } from './ModalUpdateInfos'
import { useNavigate } from 'react-router-dom'

export const MonCompte = () => {

    const navigate=useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const [userInfos, setUserInfos] = useState(SessionData().getUser())
    const [isOpen, setIsOpen] = useState(false)
    const [titreModal, setTitreModal] = useState("du mot de passe")
    const [modalBody, setModalBody] = useState("mdp")

    const deconnexion = () =>{
        if(window.confirm("Voulez-vous vous déconnecter ?") == true)
        {
            SessionData().resetUserInfos()

            navigate('/connexion')
        }
        
    }

    const openModalInfos = () =>{
        setTitreModal("des infos personnelles")
        setModalBody("infos")

        setIsOpen(true)
    }

    const openModalMDP = () =>{
        setTitreModal("du mot de passe")
        setModalBody("mdp")

        setIsOpen(true)
    }

    const hideModal = () => {
        setIsOpen(false);
    }

    const updateInfos = () =>{
        setUserInfos(SessionData().getUser())
    }

    const formatTel = valeur=>{
        let tel = ""
        for(let i = 0; i<valeur.length; i++)
        {
            tel += valeur[i]
            if(i == 2)
                tel+=" "
            
            if(i == 5)
                tel+="-"
        }

        return tel
    }

    if(SessionData().getEtatSession() == false)
    {
        //permet de changer l'url et aller vers le composant de connexion
        //window.history.pushState({}, '', '/connexion')
        //navigate('/connexion')
        navigate('/connexion')

        return null
    }
    else
    {

        return (
            <div>
                <div className="container p-4">
                    <div className="row mb-3">
                        <h2 className="moyen-texte">Bonjour {userInfos.prenom}</h2>
                    </div>
                    <div className="row rounded shadow p-2 bg-white mb-4">
                        <div className="row ">
                            <div className="col-10 col-sm-11 ">
                                <h3 className="fw-bold texte-secondaire">Infos personnelles</h3>
                            </div>
                            <div className="col-2 col-sm-1 text-end">
                                <button className="btn ms-auto" onClick={openModalInfos} ><FontAwesomeIcon icon={faPen} /></button>
                            </div>
                            <hr />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <p id=""><FontAwesomeIcon icon={faHashtag} className="me-2"/>{userInfos.idUser}</p>
                                <p id=""><FontAwesomeIcon icon={faUser} className="me-2"/>{userInfos.prenom}</p>
                                <p id=""><FontAwesomeIcon icon={faUser} className="me-2"/>{userInfos.nom}</p>
                                <p id=""><FontAwesomeIcon icon={faAt} className="me-2"/>{userInfos.email}</p>
                                <p id=""><FontAwesomeIcon icon={faPhone} className="me-2"/>{formatTel(userInfos.tel)}</p>
                                <p id=""><FontAwesomeIcon icon={faHashtag} className="me-2"/>{userInfos.matricule}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row rounded shadow p-2 bg-white mb-3">
                        <div className="row ">
                            <div className="col-10 col-sm-11">
                                <h3 className="fw-bold texte-secondaire">Mot de passe</h3>
                            </div>
                            <div className="col-2 col-sm-1 text-end">
                                <button className="btn ms-auto" onClick={openModalMDP} ><FontAwesomeIcon icon={faPen} /></button>
                            </div>
                            <hr />
                        </div>
                        <div className="row">
                            <div className="col-12 ">
                                <p id="">*********</p>
                            </div>
                        </div>
                    </div>
    
                    {/* <div className="row">
                        <div className="text-center">
                            <button className="btn btn-danger" onClick={deconnexion}>Se déconnecter</button>
                        </div>
                    </div> */}
                </div>
    
                <Modal show={isOpen} onHide={hideModal}>
                    <Modal.Body>
                        <Modal.Header>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="text-end">
                                        <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-11 text-center">
                                        <h3>Modification {titreModal}</h3>
                                    </div>
                                </div>
                            </div>
                            
                            </Modal.Header>
                        <ModalBody>
                            { modalBody==="mdp" ? <ModalUpdateMDP /> : <ModalUpdateInfos updateInfos={updateInfos}/>  }
                                
                        </ModalBody>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}