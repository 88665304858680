import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { connectAPI } from '../helpers/api'
import { SessionData } from '../helpers/SessionData'
import { validerFormUpdateInfos } from '../helpers/validationFormUpdateInfos'
import InputMask from 'react-input-mask'
import { useNavigate } from 'react-router-dom'


export const ModalUpdateInfos = props => {

    let user = SessionData().getUser()

    const navigate=useNavigate()
    const [nom, setNom] = useState(user.nom)
    const [prenom, setPrenom] = useState(user.prenom)
    const [email, setEmail] = useState(user.email)
    const [tel, setTel] = useState(user.tel)
    const [matricule, setMatricule] = useState(user.matricule)

    const [erreurNom, seterreurNom] = useState("")
    const [erreurPrenom, seterreurPrenom] = useState("")
    const [erreurEmail, seterreurEmail] = useState("")
    const [erreurMatricule, seterreurMatricule] = useState("")
    const [erreurTel, seterreurTel] = useState("")

    const [resultatModif, setResultatModif] = useState("")
    const [couleurResultat, setCouleurResultat] = useState("")

    const [visible, setvisible] = useState("d-none")

    const erreursInitiales = () =>{
        seterreurEmail("")
        seterreurMatricule("")
        seterreurNom("")
        seterreurPrenom("")
        seterreurTel("")
    }

    const handleNomChange=(event)=>{
        setNom(event.target.value)
    }

    const handlePrenomChange=(event)=>{
        setPrenom(event.target.value)
    }

    const handleEmailChange=(event)=>{
        setEmail(event.target.value)
    }

    const handleMatriculeChange=(event)=>{
        setMatricule(event.target.value)
    }

    const handleTelChange=(event)=>{
        setTel(event.target.value)
    }

    const handleSubmit = event=>{
        event.preventDefault()
        
        let validation = validerFormUpdateInfos(nom, prenom, email, matricule, formatTel(tel))
        
        erreursInitiales()

        if(!validation.isValid)
        {
            seterreurTel(validation.tel)
            seterreurNom(validation.nom)
            seterreurPrenom(validation.prenom)
            seterreurMatricule(validation.matricule)
            seterreurEmail(validation.email)
        }
        else{
            setvisible("d-block")
            updateInfos()
        }
    }

    const updateInfos = async () =>{
        setNom(lettreMajuscule(nom))
        setPrenom(lettreMajuscule(prenom))

        let data = {
            "idUser": SessionData().getIdUser(),
            "avatar":1,
            "matricule" : matricule,
            "firstName" : lettreMajuscule(prenom),
            "lastName" : lettreMajuscule(nom),
            "phoneNumber" : formatTel(tel),
            "email" : email
        }

        const result = await connectAPI("update_user.php", data)

        setvisible("d-none")

        if(result.data == null)
        {
            SessionData().resetUserInfos()
            navigate('/connexion')
        }
        else if(result.data.succes == true)
        {
            SessionData().setUserInfos({
                "nom":lettreMajuscule(nom),
                "prenom": lettreMajuscule(prenom),
                "email": email,
                "matricule": matricule,
                "idUser" : SessionData().getIdUser(),
                "tel": formatTel(tel),
                "st": SessionData().getSessionToken()
            })
            setResultatModif("Modifications réussies")
            setCouleurResultat("text-success")
            props.updateInfos()
        }
        else 
        {
            setResultatModif("Une erreur s'est produite. Veuillez réessayer plus tard")
            setCouleurResultat("text-danger")
        }
    }

    const lettreMajuscule = texte =>
    {
        if(texte.includes(" "))
        {
            let retour = ""
            let tab = texte.split(" ")

            for(let i=0; i< tab.length ; i++)
            {
                retour += tab[i].charAt(0).toUpperCase() + tab[i].slice(1) + " "
            }
            return retour.trim()
        }
        else if(texte.includes("-"))
        {
            let retour = ""
            let tab = texte.split("-")

            for(let i=0; i< tab.length ; i++)
            {
                retour += tab[i].charAt(0).toUpperCase() + tab[i].slice(1) + "-"
            }
            return retour.slice(0, -1)
        }
        else
            return texte.charAt(0).toUpperCase() + texte.slice(1)
    }

    const formatTel = valeur=>{
        valeur = valeur.replace(" ", "")
        valeur = valeur.replace("-", "")
        return valeur
    }

    return (
        <div>
            <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                    <div className="col-1 mx-auto text-center">
                        <FontAwesomeIcon icon={faSpinner} spin size="2x" className={visible + " text-center"} />
                    </div>
                </div>
                <div className={"text-center " + couleurResultat}  >
                    <h5>{resultatModif}</h5>
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="prenom" className="form-label fs-5 ">Prénom *</label>
                    <input type="text" className="form-control" onChange={handlePrenomChange} required aria-label="Prénom" aria-describedby="prenom" value={prenom} />
                    <div className="text-start form-text col-12 text-danger">{erreurPrenom}</div>
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="nom" className="form-label fs-5 ">Nom *</label>
                    <input type="text" className="form-control" onChange={handleNomChange} required aria-label="Nom" aria-describedby="nom" value={nom}/>
                    <div className="text-start form-text col-12 text-danger">{erreurNom}</div>
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="email" className="form-label fs-5 ">Email *</label>
                    <input type="text" className="form-control" onChange={handleEmailChange} required aria-label="Email" aria-describedby="email" value={email}/>
                    <div className="text-start form-text col-12 text-danger">{erreurEmail}</div>
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="matricule" className="form-label fs-5 ">Matricule</label>
                    <input type="text" className="form-control" onChange={handleMatriculeChange} required aria-label="Matricule" aria-describedby="matricule" value={matricule}/>
                    <div className="text-start form-text col-12 text-danger">{erreurMatricule}</div>
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="tel" className="form-label fs-5 ">Téléphone *</label>
                    {/* <input type="text" className="form-control" onChange={handleTelChange} required aria-label="Téléphone" aria-describedby="telephone" value={tel}/> */}
                    <InputMask mask="999 999-9999" maskChar=" " className="form-control" onChange={handleTelChange} value={tel} required/>
                    <div className="text-start form-text col-12 text-danger">{erreurTel}</div>
                </div>
                <div className=" mb-3 text-center">
                    <button type="submit" id="envoyer" className='btn btn-primary text-white btn-lg col-12 col-md-6'>Modifier</button>
                </div>

            </form>
            
        </div>
    )
}
