import React from 'react'

export const LigneHistorique = props=> {

    let texteStatut = ""

    if(props.ligne.state == "disponible")
        texteStatut = "Concession cédée"

    if(props.ligne.state == "refuser")
        texteStatut = "Livre refusé"
    
    if(props.ligne.state == "retirer")
        texteStatut = "Livre retiré de la vente"
    
    if(props.ligne.state == "validation")
        texteStatut = "En attente de validation"
    
    if(props.ligne.state == "nonpaye")
        texteStatut = "Don de l'argent"

    if(props.ligne.state == "paye")
        texteStatut = "Concession payée"

    return (
        <tr>
            <td>{props.index + 1}</td>
            <td>{props.ligne.title}</td>
            <td>{props.ligne.price}$</td>
            <td>{texteStatut}</td>
            <td>{props.ligne.haveInternetCode == 1 ? "Oui" : ""}</td>
        </tr>
    )
}
