import axios from 'axios'
import { SessionData } from './SessionData'

export const connectAPI = (lien, data) => {

    /* const headers = { 
        'content-type': 'application/json',
        'Authorization': 'Bearer 852852852' 
    } */

    
    let token = SessionData().getSessionToken()
    let id = SessionData().getIdUser()

    axios.defaults.headers.common = {
        'Authorization': `bearer ${token}-${id}`,
        'Project':'value LIVRES-USAGES-AGECTR',
        'From':'value WEB'
    }

    //axios.post("../../Api/functions/get_detail_search.php", formData, {headers})
    return axios.post("../../Api/functions/" + lien, data)
        /*    .then(res => {
                console.log(res.data);
                //setListePopulaires(res.data)
            })
            .catch(erreur => {
                console.log(erreur);
            })
            */
}