import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

function ModalAvertissement({ isModalAvertissementOpen, handleModalAvertissementClose }) {
    
    
  return (
    <Modal  show={isModalAvertissementOpen} backdrop="static" keyboard={false}>
      <Modal.Header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-11">
              <h3>Avertissement !</h3>
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <h5>
          Vous disposez de <span className="text-primary font-weight-bold">2 jours ouvrables</span> à partir de la date de
          réservation pour récupérer vos livres.
        </h5>
        <h5>Passé ce délai, vos réservations seront annulées</h5>
        <br />

        <p>
          Rendez vous dans la section <Link to="/compte/reservations" >Mes reservations</Link> pour plus d'informations.

        </p>
        
      </Modal.Body>
      <Modal.Footer>
        <div className="text-end">
          <button className="btn btn-primary text-white btn-lg" onClick={handleModalAvertissementClose}>
            J'ai compris
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAvertissement;
