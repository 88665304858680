import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faFacebookF, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons"

import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
    return (
        <div>
            <div className="container-fluid bg-dark  py-4">
                <div className="row  text-white mb-1 fs-5">
                    <div className="col-12 col-md-8 col-lg-10 mx-auto my-2">
                        <div className="row">
                            <div className="  col-md-2 text-center my-2">
                                <Link to="/contact" className="text-decoration-none text-white ">Contact</Link>
                            </div>
                            <div className="col-md-2  text-center my-2">
                                <a href="https://agectr.ca/" target='_blank' className="text-decoration-none text-white" rel="noreferrer">agectr.ca</a>
                            </div>
                            <div className="col-md-4 text-center  my-2">
                                {/* <a href="idModalConditionsFooter" className="text-decoration-none text-white" data-bs-toggle="modal" data-bs-target="#idModalConditionsFooter">Conditions d'utilisation</a> */}
                                <Link to="/conditions" className="text-decoration-none text-white" >Conditions d'utilisation</Link>
                            </div>
                            <div className="col-md-4 text-center  my-2">
                                <Link to="/politique" className="text-decoration-none text-white ">Politique de confidentialité</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8 mx-auto text-white">
                        <hr />
                    </div>
                </div>

                <div className="row text-white ">
                    <div className="col-12  align-self-center text-center">
                        <a href="https://www.facebook.com/agectr" target='_blank' className="text-white" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} size="2x" className="me-4" />
                        </a>
                        <a href="https://www.instagram.com/agecegeptr/"  target='_blank' className="text-white" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} size="2x"/>
                        </a>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="idModalConditionsFooter" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="idModalConditionsFooterLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title fw-bold" id="idModalConditionsFooterLabel">Conditions d'utilisation</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>
                            Par le présent contrat, je m’engage à respecter les règlements de la consigne de livres usagés. <br />
                            Ainsi:   
                        </p>

                        <ul>
                            <li>
                                Je consens à respecter la date limite pour la remise de l’argent et le renouvellement de mes livres et à ne pas revenir contre l’AGECTR inc. si je ne respecte pas la date (contrat d’un an);
                            </li>
                            <li>
                                Je consens que l’AGECTR inc. conserve mes livres et/ou l’argent provenant de la vente de ces derniers si je ne respecte pas la date limite pour récupérer mes biens;
                            </li>
                            <li>
                                Je prends note que, si les livres ne sont pas vendus, je peux toujours renouveler le contrat directement au bureau de l’AGECTR ou par l’application mobile ou sur le site web avant la date limite;
                            </li>
                            <li className="fw-bold">
                                L’AGECTR inc. se dégage de toute responsabilité en ce qui a trait au vol ou aux pertes de volumes.  Elle fera cependant tout ce qui est en son pouvoir afin d’éviter de telles situations.
                            </li>
                        </ul>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                    </div>
                    </div>
                </div>
            </div>
        


        </div>

        
            
    )
}
