import React, { useEffect, useRef, useState } from "react";
import carte from "../img/map.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faMapMarkerAlt,
  faPhoneSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { connectAPI } from "../helpers/api";
import Horaire from "../composants/Horaire";

export const Contact = () => {
  const [ouvert, setOuvert] = useState(true);
  const [horaire, setHoraire] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getHoraire();
  }, []);

  let contenu = useRef("");

  const getHoraire = async () => {
    const result = await connectAPI("get_horaire.php", null);

    if (result.data.length == 0) {
      setOuvert(false);
    //   let texte = document.createElement("h3");
    //   texte.innerText = "Nous sommes présentement fermés.";
    //   texte.classList.add("text-center");
    //   texte.style.color = "red";
    //   contenu.current.appendChild(texte);
    } 
    else 
    {
      setOuvert(true);
      setHoraire(result.data);
      console.log(horaire)
    //   for (let i = 0; i < result.data.length; i++) {
    //     let ligne = document.createElement("div");
    //     ligne.classList.add("row");

    //     let col1 = document.createElement("div");
    //     col1.classList.add("col");

    //     let col2 = document.createElement("div");
    //     col2.classList.add("col");

    //     let jour = document.createElement("h4");
    //     jour.innerText = result.data[i].jour;
    //     col1.appendChild(jour);

    //     let heures = document.createElement("h4");
    //     heures.innerText = result.data[i].debut + " - " + result.data[i].fin;
    //     col2.appendChild(heures);

    //     ligne.appendChild(col1);
    //     ligne.appendChild(col2);

    //     contenu.current.appendChild(ligne);
    //   }
    }
  };

  return (
    <div>
      <div className="container-fluid h-100  parallax bg-contact">
        <div className="row pt-5">
          <div className="col-12 p-5">
            <h1 className="text-end text-white titre">CONTACT</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid py-3 mt-4">
        {ouvert === false ? (
          <h3 className="text-center text-danger">
            Nous sommes présentement fermés.
          </h3>
        ) : (
          <>
            <div className="row text-center ">
              <h1 className="fw-semibold"> Nos heures d'ouverture</h1>
            </div>

            <div className="row mt-1">
              <div className="col-10 col-md-6 mx-auto">
                {horaire.map((hor) => (
                  <Horaire
                    jour={hor.jour}
                    debut={hor.debut}
                    fin={hor.fin}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>

      <div className="container-fluid py-3">
        <div className="row text-center">
          <h1 className="fw-semibold">Nous suivre</h1>
        </div>
        <div className="row">
          <h4 className="text-center">Suivez-nous sur les réseaux sociaux</h4>
        </div>
        <div className="row texte-primaire p-lg-5 p-0">
          <div className="col-6 text-end">
            <a
              href="https://www.facebook.com/agectr"
              className="texte-primaire"
            >
              <FontAwesomeIcon icon={faFacebookSquare} size="8x" />
            </a>
          </div>
          <div className="col-6">
            <a
              href="https://www.instagram.com/agecegeptr/"
              className="texte-primaire"
            >
              <FontAwesomeIcon icon={faInstagram} size="8x" />
            </a>
          </div>
        </div>
      </div>

      <div className="container-fluid py-3">
        <div className="row text-center">
          <h1 className="fw-semibold"> Nous joindre</h1>
        </div>
        <div className="row p-lg-5 p-0">
          <div className="col-12 col-lg-10 mx-auto">
            <div className="row g-0 g-lg-3">
              <div className="col-12 col-md-6 ">
                <div className="card shadow border-0 text-center mb-5">
                  <div className="card-body">
                    <FontAwesomeIcon
                      icon={faPhoneSquare}
                      className="texte-primaire"
                      size="10x"
                    />
                  </div>
                  <div className="card-footer py-4 primaire text-white mt-4">
                    <p className="fs-5 fs-md-6 fs-lg-4">
                      <a
                        href="tel:+18193761721"
                        className="text-decoration-none text-white"
                      >
                        1-819-376-1721 poste 2524
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 ">
                <div className="card shadow border-0 text-center">
                  <div className="card-body">
                    <FontAwesomeIcon
                      icon={faAt}
                      className="texte-primaire"
                      size="10x"
                    />
                  </div>
                  <div className="card-footer py-4 primaire px-0 text-white mt-4">
                    <p className="fs-5 fs-lg-4">
                      <a
                        href="mailto:scomptable.agectr@gmail.com"
                        className="text-decoration-none text-white"
                      >
                        scomptable.agectr@gmail.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-3">
        <div className="row text-center">
          <h1 className="fw-semibold">Où nous trouver</h1>
        </div>
        <div className="row p-lg-5 p-0">
          <div className="col-12 col-lg-10 mx-auto rounded shadow-lg bg-white">
            <div className="row">
              <div className="col-12 col-lg-7">
                <img
                  src={carte}
                  className="img-fluid"
                  alt="Association Générale Étudiante Cégep de Trois-Rivières"
                />
              </div>
              <div className="col-12 col-lg-5 align-self-center texte-primaire p-5">
                <div className="row text-center">
                  <div className="mb-3">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="text-center"
                      size="5x"
                    />
                  </div>
                  <p className="fs-md-1 fs-3">
                    Pavillon des Humanités
                    <br />
                    HA-1152 <br />
                    3175 Boulevard Laviolette <br />
                    Trois-Rivières, QC <br />
                    G8Z 1E9
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
