export const validerFormUpdateMDP = (ancienMDP, nouveauMDP, confirmationMDP) =>
{
    let valid = {
        ancien:'',
        nouveau:'',
        confirmation:'',
        isValid: true
    }

    let mdpregex = "^(?=.{8,})(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!?*()@#$%^&+=.-_]).*$"
    
    if(ancienMDP.trim() === "")
    {
        valid.ancien = "Vous devez entrer une valeur"
    }

    //validation du mot de passe
    if(!nouveauMDP.trim().match(mdpregex))
    {
        valid.nouveau = "Le mot de passe doit" 
        valid.nouveau += " avoir au moins 8 caractères,"
        valid.nouveau += " au moins une lettre minuscule,"
        valid.nouveau += " au moins une lettre majuscule,"  
        valid.nouveau += " au moins un chiffre," 
        valid.nouveau += " au moins un caractère spécial !?*()@#$%^&+=."
        valid.isValid = false
    }
    else{
        if(nouveauMDP.trim() !== confirmationMDP.trim())
        {
            valid.confirmation = 'Les mots de passe doivent être identiques'
            valid.isValid = false
        }
    }

    return valid
}

