export const livreChoisi = (props) => {

    sessionStorage.setItem("idBook", props.livre.idBook)
    sessionStorage.setItem("titre", props.livre.title)
    sessionStorage.setItem("auteur", props.livre.author)
    sessionStorage.setItem("edition", props.livre.edition)
    sessionStorage.setItem("editeur", props.livre.publisher)
    sessionStorage.setItem("codebarre", props.livre.barcode)
    sessionStorage.setItem("urlPhoto", props.livre.urlPhoto)
    sessionStorage.setItem("nombre", props.nb)

}

export const getLivreChoisi = () => {

    return {
        "idBook" : sessionStorage.getItem("idBook"),
        "titre" : sessionStorage.getItem("titre"),
        "auteur" : sessionStorage.getItem("auteur"),
        "edition" : sessionStorage.getItem("edition"),
        "editeur" : sessionStorage.getItem("editeur"),
        "codebarre" : sessionStorage.getItem("codebarre"),
        "urlPhoto" : sessionStorage.getItem("urlPhoto"),
        "nombre" : sessionStorage.getItem("nombre")
    }

}
