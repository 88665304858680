import {
  faBook,
  faChevronLeft,
  faChevronRight,
  faHandHoldingUsd,
  faSearch,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import axios from "axios";
import { LivresAccueil } from "./LivresAccueil";
import $ from "jquery";
import { connectAPI } from "../helpers/api";

export const Defaut = () => {
  const scroll = useRef(null);

  const [populaires, setListePopulaires] = useState([]);
  const [recents, setListeRecents] = useState([]);

  useEffect(() => {
    getBestSellers();
    getRecents();
  }, []);

  //code jquery pour le défilement des livres récents et populaires

  $(".right").on("click", function () {
    $(this).parent().stop().animate({ scrollLeft: "+=520" }, 800);
    return false;
  });

  $(".left").on("click", function () {
    $(this).parent().stop().animate({ scrollLeft: "-=520" }, 800);
    return false;
  });
  //----------------------------------------------------------------

  const getBestSellers = async () => {
    const result = await connectAPI("best_sellers.php", null);
    if (result.data != null) {
      setListePopulaires(result.data);
    }
  };

  const getRecents = async () => {
    const result = await connectAPI("most_recently_added.php", null);
    if (result.data != null) {
      setListeRecents(result.data);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row py-4 ">
          <div className="col-12 text-center">
            <h1>Bienvenue</h1>
            <h3>
              L'association générale des étudiants du Cégep de Trois-Rivières
              offre un service de livres usagés
            </h3>
          </div>
        </div>
      </div>

      <div className="container mt-3 ">
        <div className="row">
          <div className="col-10 col-md-6 mx-auto mb-3">
            <div className="shadow rounded ">
              <div className="primaire text-center text-white p-4">
                <FontAwesomeIcon icon={faShoppingBag} size="3x" />
                <h3 className="mt-2">Achat de livres usagés</h3>
              </div>
              <div className="bg-white p-3 mb-3 texte-justufie">
                <p className="fs-5">
                  Recherchez les livres dont vous avez besoin, par titre, par
                  auteur ou par ISBN, réservez-les et venez les récupérer au
                  local HA-1152, au pavillon des Humanités. Nous acceptons les
                  modes de paiement au comptant et par débit.
                  <br />
                  L’achat des livres usagés peut également se faire directement
                  à notre local. Nos inventaires dépendent des livres rapportés
                  par les étudiants. Chaque étudiant détermine le prix de vente
                  de ses livres.
                </p>
              </div>
            </div>
          </div>
          <div className="col-10 col-md-6 mx-auto mb-3">
            <div className="shadow">
              <div className="primaire text-center text-white p-4">
                <FontAwesomeIcon icon={faHandHoldingUsd} size="3x" />
                <h3 className="mt-2">Vente de livres usagés</h3>
              </div>
              <div className="bg-white p-3 mb-3 texte-justufie">
                <p className="fs-5">
                  Ajoutez vos livres à vendre dans l’onglet « mes livres » et
                  déposez-les en consignation au local HA-1152, au pavillon des
                  Humanités, pour qu’ils puissent être visibles à la vente.{" "}
                  <br />
                  <br />
                  Une fois vendus, vous recevrez un courriel et vous pourrez
                  passer au local récupérer votre argent. <br />
                  La vente des livres usagés peut également se faire directement
                  à notre local.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5 ">
        <div className="row mb-2">
          <h3 className="fw-semibold">Livres populaires</h3>
        </div>

        <div className="row bg-white shadow py-4">
          <div className="col-12">
            <div className="sidescroll" ref={scroll}>
              <div className="left">
                <button className="arrow">
                  <FontAwesomeIcon icon={faChevronLeft} size="2x" />
                </button>
              </div>

              {populaires.map((unlivre) => (
                <LivresAccueil
                  key={unlivre.book.idBook}
                  nb={unlivre.amount}
                  livre={unlivre.book}
                />
              ))}

              <div className="right">
                <button className="arrow">
                  <FontAwesomeIcon icon={faChevronRight} size="2x" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-2 mt-5">
          <h3 className="fw-semibold">Livres ajoutés récemment</h3>
        </div>

        <div className="row bg-white shadow py-4">
          <div className="col-12">
            <div className="sidescroll">
              <div className="left">
                <button className="arrow">
                  <FontAwesomeIcon icon={faChevronLeft} size="2x" />
                </button>
              </div>

              {recents.map((unlivre) => (
                <LivresAccueil
                  key={unlivre.book.idBook}
                  nb={unlivre.amount}
                  livre={unlivre.book}
                />
              ))}

              <div className="right">
                <button className="arrow">
                  <FontAwesomeIcon icon={faChevronRight} size="2x" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
