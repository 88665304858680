import React, { useEffect } from "react";
// politiques de confidentialité
function Politique() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="politique bg-white">
      <div className="container-fluid primaire py-5">
        <div className="row text-end pe-5">
          <h1 className="text-white fw-bold my-5 ">Conditions d'utilisation</h1>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="row ">
          <div className="col-12 col-md-10 col-lg-8 mx-auto">
            <p className="mb-5 intro">
              Les conditions d'utilisation suivantes s'appliquent à tous les
              utilisateurs et visiteurs du site Web <strong>livres-usages.agectr.com</strong>. En
              accédant à ce site Web, vous acceptez d'être lié par ces
              conditions d'utilisation. Si vous n'acceptez pas ces conditions,
              vous ne pouvez pas bénéficier de nos services.
            </p>

            <div className="mb-5">
              <h2 className="fw-semibold">Consentement</h2>
              <p>
                Vous consentez à respecter la date limite pour la remise de
                l’argent et le renouvellement de mes livres et à ne pas revenir
                contre l’AGECTR inc. si vous ne respectez pas la date (contrat
                d’un an);
              </p>
              <p>
                Vous consentez que l’AGECTR inc. conserve vos livres et/ou
                l’argent provenant de la vente de ces derniers si vous ne
                respectez pas la date limite pour récupérer mes biens
              </p>
              <p>
                Vous prenez note que, si les livres ne sont pas vendus, vous
                pouvez toujours renouveler le contrat directement au bureau de
                l’AGECTR ou par l’application mobile ou sur le site Web avant la
                date limite.
              </p>
            </div>

            <div className="mb-5">
              <h2 className="fw-semibold">Dégagement de responsabilité</h2>
              <p>
                L’AGECTR inc. se dégage de toute responsabilité en ce qui a
                trait au vol ou aux pertes de volumes. Elle fera cependant tout
                ce qui est en son pouvoir afin d’éviter de telles situations.
              </p>
            </div>

            <div className="mb-5">
              <h2 className="fw-semibold">Témoins (cookies)</h2>
              <p>
                Le site internet n'utilise pas de témoins (cookies) pour suivre
                votre navigation. <br />
                Ainsi, aucune information personnelle n'est collectée à votre
                insu.
              </p>
            </div>

            <div className="mb-5">
              <h2 className="fw-semibold">Liens vers l'extérieur</h2>
              <p>
                Le site Web peut contenir des liens vers des sites Web de tiers
                ou des services qui ne sont pas détenus ou contrôlés par
                l'AGECTR.
              </p>
              <p>
                Ces sites Web pourraient avoir des temoins qui recueillent des
                informations personnelles.
              </p>
              <p>
                L'AGECTR n'a aucun contrôle sur ces sites Web de tiers et
                n'assume aucune responsabilité pour leur contenu, politiques de
                confidentialité ou pratiques.
              </p>
            </div>

            <div className="mb-5">
              <h2 className="fw-semibold">Limitations</h2>
              <p>
                Nous ne pouvons pas assurer que toutes les fonctionnalités du
                site internet seront toujours disponibles de manière
                ininterrompue, sans erreurs, virus ou autres éléments nuisibles.
              </p>
              <p>
                L'AGECTR ne peut être tenue responsable des pertes de données ou
                de dommages matériels consécutifs à l'utilisation du site Web.
              </p>
            </div>
          </div>
        </div>
        <div className="row py-5">
          <div className="col-12 text-center">
            <h3>
              Dernière mise à jour: <strong>13 mai 2024</strong>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Politique;
