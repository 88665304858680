import React, { useEffect, useRef, useState } from 'react'
import { connectAPI } from '../helpers/api'

export const Annonce = ()=>{
    
    const [contenu, setcontenu] = useState("")
    const [visible, setvisible] = useState("d-none")
    const texte = useRef("");

    useEffect(() => {
        getAnnonce()
    }, [])
    
    const getAnnonce = async ()=>{
        const result = await connectAPI("get_annonce.php", null)
        
        if(result.data != false)
        {
            //setcontenu(result.data)
            texte.current.innerHTML  = result.data
            setvisible("d-block")
        }
        else
        {
            setvisible("d-none")
        }

    }
    
    return (
        <div className={visible + " container my-3"}>
            <div className="row py-3 px-1  bg-danger text-white">
                <div className="col-12" ref={texte}>
                    
                </div>
            </div>
        </div>
    )
}
