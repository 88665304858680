import React, { useEffect, useState } from 'react'
import { SessionData } from '../helpers/SessionData';
import { SessionExpiree } from '../pages/SessionExpiree';
import { useNavigate } from 'react-router-dom';

export const MesLivres = props => {

    const navigate=useNavigate()
    
    useEffect(() => {
        if(props.ex.state == "disponible")
        {
            settexteStatut("En vente")
            setcouleur("bg-success")
        }
            

        if(props.ex.state == "refuser")
        {
            settexteStatut("Refusé")
            setcouleur("bg-danger")
        }
        
        if(props.ex.state == "a_retirer")
        {
            settexteStatut("Retiré")
            setcouleur("bg-secondary")
        }
        
        if(props.ex.state  == "modification")
        {
            settexteStatut("Validation")
            setcouleur("bg-warning")
        }
        
        if(props.ex.state  == "a_paye")
        {
            settexteStatut("Vendu")
            setcouleur("bg-primary")
        }

        if(props.ex.state  == "renouveler")
        {
            settexteStatut("À renouveler")
            setcouleur("bg-info")
        }
        
        if(props.ex.state == "validation")
        {
            settexteStatut("À déposer")
            setcouleur("bg-warning")
        }
    })

    const [couleur, setcouleur] = useState("")
    const [texteStatut, settexteStatut] = useState("")

    const handleClicExemplaire = () =>{
        props.getExemplaireChoisi(props.position)
    }

    let dossierPhoto = ""
    let photo = ""

    

    if(props.ex.state == "disponible" && props.ex.urlPhoto != null)
    {
        dossierPhoto = "upload_photo_concession"
        photo = props.ex.urlPhoto
    }
    else if(props.ex.state == "validation" && props.ex.urlPhoto != null)
    {
        dossierPhoto = "upload_photo_reception"
        photo = props.ex.urlPhoto
    }
    else
    {
        dossierPhoto = "upload_photo_book"
        photo = props.ex.book.urlPhoto
    }
    
    if(SessionData().getEtatSession() == false)
    {
        //permet de changer l'url et aller vers le composant de connexion
        //window.history.pushState({}, '', '/connexion')
        //navigate('/connexion')
        navigate('/connexion')

        return null
    }
    else
    {

        return (
            <div className="col">
                <div className="card shadow " id={props.ex.idConcession} onClick={handleClicExemplaire} >
                    <div className="row g-0">
                        <div className="col-4">
                            <img src={"http://agectr.com/livreUsage/GlobalAGECTR/" + dossierPhoto + "/"+photo+".png"} className="img-fluid hauteur-img" />
                        </div>
                        <div className="col-8">
                            <div className="card-body pt-0">
                                <div className="row p-0 mb-2">
                                    <div className={couleur + " col-5 offset-7 text-center text-white"}>
                                        <small>{texteStatut}</small>
                                    </div>
                                </div>
                                <div className="row">
                                    <h5 className="card-text text-end fst-italic">{props.ex.book.barcode}</h5>
                                </div>
                                <div className="row">
                                    <h5 className="card-title fw-bold">
                                        {props.ex.book.title}
                                    </h5>
                                </div>
                                <div className="row mb-1">
                                    <h6 className="card-text">{props.ex.book.author}</h6>
                                </div>
                                <div className="row mb-1">
                                    <h6 className="card-text">{props.ex.book.edition}</h6>
                                </div>
                                <div className="row mb-1">
                                    <h4 className="card-text ">{props.ex.customerPrice}$</h4>
                                </div>
                                <div className="row  mb-1">
                                    <small className="card-text ">{props.ex.isAnnotated ? "annotations" : ""}{props.ex.haveInternetCode ? " Code Internet" : ""}</small> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

