import React from "react";
import { useState, useEffect, useContext, createContext } from "react";
import { Navbar } from "./composants/Navbar";
import { Accueil } from "./pages/Accueil";
import { Contact } from "./pages/Contact";
import { FAQ } from "./pages/FAQ";
import { Erreur } from "./pages/Erreur";
import Recherche from "./pages/Recherche";
import Connexion from "./pages/Connexion";
import Inscription from "./pages/Inscription";
import {
  BrowserRouter,
  RouterProvider,
  createBrowserRouter,
  Routes,
  Route,
  Outlet,
  Link,
} from "react-router-dom";
import { Livres } from "./pages/Livres";
import { Reservations } from "./pages/Reservations";
import { Footer } from "./composants/Footer";
import { Exemplaires } from "./pages/Exemplaires";
import { MonCompte } from "./composants/MonCompte";
import { MonLivre } from "./composants/MonLivre";
import { Historique } from "./pages/Historique";
import Politique from "./pages/Politique";
import Conditions from "./pages/Conditions";


function App() {
  // document.title = "Livres usagés - AGECTR";
  // const match = useRoutes(routes)

  return (
    <BrowserRouter >
        
        <Navbar />
        <div className="min-vh-100">
          <Routes>
            <Route exact path="/" element={<Accueil />} />
            <Route path="/contact" element={<Contact />} />
            <Route path='/connexion' element={<Connexion />} />
            <Route path='/inscription' element={<Inscription />} />
            <Route path='/FAQ' element={<FAQ />} />
            <Route path='/recherche/:id' element={<Recherche/> }/>
            <Route path='/livres' element={<Livres />} />
            <Route path='/reservations' element= { <Reservations />} />
            <Route path='/exemplaires' element= {<Exemplaires />} />
            <Route path='/politique' element={<Politique />} />
            <Route path='/conditions' element={<Conditions />} />
            <Route path='/compte/livres' element={<Livres />} />
            <Route path='/compte/infos' element={<MonCompte />} />
            <Route path='/compte/reservations' element={<Reservations />} />
            <Route path='/compte/connexion' element={<Connexion />} />
            <Route path='/compte/monlivre' element={<MonLivre />} />
            <Route path='/compte/historique' element={<Historique />} />
            <Route path='*' element={<Erreur />} />

           </Routes>
        </div>
        <Footer />
      
    </BrowserRouter>
  );
}

export default App;

// "homepage": "http://localhost/projet/age/build/"
// "homepage": "http://livres-usages.agectr.com/"
// "homepage": "http://livres-usages.agectr.com/"