import React, { useEffect } from 'react'
import { SessionData } from '../helpers/SessionData';
import { SessionExpiree } from '../pages/SessionExpiree';
import { useNavigate } from 'react-router-dom';

export const MesReservations = props => {

    const navigate = useNavigate()
    
    const handleClicExemplaire = () =>{
        props.getExemplaireChoisi(props.position)
    }

    let dossierPhoto = ""
    let photo = ""
    let date = props.ex.reservedExpireDate.slice(0,16)
    

    if(props.ex.urlPhoto != null)
    {
        dossierPhoto = "upload_photo_reception"
        photo = props.ex.urlPhoto
    }
        
    else
    {
        dossierPhoto = "upload_photo_book"
        photo = props.ex.book.urlPhoto
    }
    
    if(SessionData().getEtatSession() == false)
    {
        //permet de changer l'url et aller vers le composant de connexion
        //window.history.pushState({}, '', '/connexion')
        //navigate('/connexion')
        navigate('/connexion')

        return null
    }
    else
    {
        return (
            <div className="col">
                <div className="card shadow " id={props.ex.idConcession} onClick={handleClicExemplaire} >
                    <div className="row g-0">
                        <div className="col-4 ">
                            <img src={"http://agectr.com/livreUsage/GlobalAGECTR/" + dossierPhoto + "/"+photo+".png"} className="img-fluid hauteur-img" />
                        </div>
                        <div className="col-8">
                            <div className="card-body">
                                <div className="row">
                                    <h5 className="card-text text-end fst-italic">{props.ex.book.barcode}</h5>
                                </div>
                                <div className="row">
                                    <h5 className="card-title">
                                        {props.ex.book.title}
                                    </h5>
                                </div>
                                <div className="row">
                                    <small className="card-text">{props.ex.book.author}</small>
                                </div>
                                <div className="row">
                                    <h3 className="card-text ">{props.ex.sellingPrice}$</h3>
                                </div>
                                <div className="row mt-2">
                                    <small>La reservation expire le:</small>
                                    <h5 className="card-text ">
                                        {date}<br />
                                        <small>Veuillez passer au bureau de l'AGECTR avant ce délai</small>
                                    </h5>
                                </div>
                                <div className="rowmt-2">
                                    <small className="card-text ">{props.ex.isAnnotated ? "annotations" : ""}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

