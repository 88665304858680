import React from 'react'

export const SessionData = () =>{

    const getUser = () =>{
        return {
            "connecte" : sessionStorage.getItem("connecte"),
            "nom": sessionStorage.getItem("nom"),
            "prenom": sessionStorage.getItem("prenom"),
            "email": sessionStorage.getItem("email"),
            "matricule": sessionStorage.getItem("matricule"),
            "idUser": sessionStorage.getItem("idUser"),
            "tel": sessionStorage.getItem("tel")
        }
    }

    const setUserInfos = (user) => {
        sessionStorage.setItem("nom", user.nom)
        sessionStorage.setItem("prenom", user.prenom)
        sessionStorage.setItem("email", user.email)
        sessionStorage.setItem("matricule", user.matricule)
        sessionStorage.setItem("tel", user.tel)
        sessionStorage.setItem("idUser", user.idUser)
        sessionStorage.setItem("st", user.st)
    }

    const getIdUser = ()=>{
        return sessionStorage.getItem("idUser")
    }

    const getSessionToken = ()=>{
        return sessionStorage.getItem("st")
    }

    const getEtatSession= ()=>{
        return sessionStorage.getItem("nom") !== null && sessionStorage.getItem("prenom")!== null && sessionStorage.getItem("email")!== null && sessionStorage.getItem("idUser")!== null
    }

    const resetUserInfos = () =>{
        sessionStorage.removeItem("connecte")
        sessionStorage.removeItem("nom", '')
        sessionStorage.removeItem("prenom")
        sessionStorage.removeItem("email")
        sessionStorage.removeItem("matricule")
        sessionStorage.removeItem("tel")
        sessionStorage.removeItem("idUser")
        sessionStorage.removeItem("st")
    }

    return {
        getUser,
        setUserInfos,
        getIdUser,
        resetUserInfos,
        getEtatSession,
        getSessionToken
    }
        

} 