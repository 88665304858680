export const validerFormInscription = (valeurs) =>
{
    let valid = {
        nom:'',
        prenom:'',
        email:'',
        mdp:'',
        confirmation:'',
        tel:'',
        matricule:'',
        conditions : '',
        isValid: true
    }

    let emailregex = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
    let mdpregex = "^(?=.{8,})(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!?*()@#$%^&+=.-_]).*$"
    //validation du nom
    if(valeurs.nom.trim().length < 2)
    {
        valid.nom = 'entrez un nom valide'
        valid.isValid = false
    }

    //validation du prénom
    if(valeurs.prenom.trim().length < 2)
    {
        valid.prenom = 'Entrez un prénom valide'
        valid.isValid = false
    }

    //validation de l'email
    if( !valeurs.email.match(emailregex))
    {
        valid.email = 'Entrez un email valide'
        valid.isValid = false
    }
    else
    {
        if(valeurs.email.includes('cegeptr.qc.ca'))
        {
            valid.email = 'Les emails du cegep ne sont pas autorisés'
            valid.isValid = false
        }
        
    }

    //validation du mot de passe
    if(!valeurs.mdp.match(mdpregex))
    {
        valid.mdp = "Le mot de passe doit" 
        valid.mdp += " avoir au moins 8 caractères,"
        valid.mdp += " au moins une lettre minuscule,"
        valid.mdp += " au moins une lettre majuscule,"  
        valid.mdp += " au moins un chiffre," 
        valid.mdp += " au moins un caractère spécial !?*()@#$%^&+=."
        valid.isValid = false
    }
    else{
        if(valeurs.mdp !== valeurs.confirmation)
        {
            valid.confirmation = 'Les mots de passe doivent être identiques'
            valid.isValid = false
        }
    }

    //validation des conditions d'utilisation
    if( !valeurs.conditions)
    {
        valid.conditions = "Acceptez les conditions d'utilisation"
        valid.isValid = false
    }

    //validation du matricule si une valeur est entrée
    if( valeurs.matricule.trim().length > 0)
    {
        if( !valeurs.matricule.match("[0-9]{7}"))
        {
            valid.matricule = 'Le matricule doit contenir 7 chiffres'
            valid.isValid = false
        }
    }

    //validation du téléhone si une valeur est entrée
    if( valeurs.tel.trim().length < 12)
    {
        valid.tel = "Entrez un numéro de téléphone valide"
        valid.isValid = false
    }

    return valid
}

