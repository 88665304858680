import { event } from "jquery";
import React, { Component, useState } from "react";
import { ResRecherche } from "../composants/ResRecherche";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";

const Recherche = (props) => {
  const navigate = useNavigate();
  let {id} = useParams()

  const [livre, setLivre] = useState(decodeURI(id));
  const [search, setSearch] = useState(decodeURI(id));

  const handleLivreChange = (event) => {
    setLivre(event.target.value);
  };

  const handleButtonClick = (event) => {
    if (livre !== "") {
      setSearch(livre)

      navigate(`/recherche/${livre}`);
    }

    event.preventDefault();
  };

  return (
    <div>
      <div className="container-fluid bg-recherche py-5">
        <div className="row text-center">
          <h1 className="text-white fw-bold">Trouvez un livre</h1>
          <div className="mx-auto col-md-8 col-12 my-3">
            <form onSubmit={handleButtonClick}>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  value={livre}
                  onChange={handleLivreChange}
                  placeholder="Entrez un titre ou un ISBN"
                  aria-label="Recherche de livre"
                  aria-describedby="button-addon2"
                />
                <button
                  className="btn btn-lg primaire text-white"
                  type="submitt"
                  id="button-addon2"
                  onClick={handleButtonClick}
                >
                  <FontAwesomeIcon icon={faSearch} className="" size="1x" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ResRecherche livreRecherche={search} />
    </div>
  );
};

// class Recherche extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       livre: decodeURI(this.props.livre),
//       search: decodeURI(this.props.livre),
//     };
//   }

//   render() {
    
//   }
// }

export default Recherche;
