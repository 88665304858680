import React from 'react'
import { Link } from 'react-router-dom'

export const SessionExpiree = () =>{

  

    return (
        <div>
            <div className="container-fluid ">
                <div className="row pt-5">
                    <div className="col-12 col-sm-6 mx-auto bg-white shadow p-4 text-center">
                        <h1 className="texte-primaire display-1">OUPS !</h1>
                        <p className="texte-secondaire fs-3">
                            Il semble que la session a expiré.<br />
                            Veuillez vous reconnecter.
                        </p>
                        <Link to="/connexion" className="btn btn-primary">Page de connexion</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}