import { faArrowLeft, faBackward, faPen, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Modal, ModalBody } from 'react-bootstrap'
import { connectAPI } from '../helpers/api'
import { SessionData } from '../helpers/SessionData'
import { SessionExpiree } from '../pages/SessionExpiree'
import { Link, useNavigate } from 'react-router-dom'


export const MonLivre = props =>{

    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
        
        if(statut == "disponible")
        {
            setrenouvelerVisible("d-block")
            setdonnerVisible("d-block")
            setretirerVisible("d-block")
            setsupprimerVisible("d-block")
            setbtPrixVisible("d-block")
            setexpirationVisible("d-block")
        }

        if(statut == "validation" || statut == "refuser")
        {
            setsupprimerVisible("d-block")
        }

        if(statut == "modification")
        {
            setdonnerVisible("d-block")
            setretirerVisible("d-block")
            setbtPrixVisible("d-block")
        }

        if(statut == "a_paye")
        {
            setdonArgentVisible("d-block")
            setsousTexteStatut("Vous pouvez récupérer votre argent au local HA-1152")
        }

        if(statut == "renouveler")
        {
            setrenouvelerVisible("d-block")
            setdonnerVisible("d-block")
            setretirerVisible("d-block")
        }

        if(statut == "a_retirer"){
            setdonnerVisible("d-block")
        }

        if(statut == "refuser"){
            setsousTexteStatut(raisonRefus)
        }


    }, [])

    const idBook = sessionStorage.getItem("monlivre_idBook") 
    const titre = sessionStorage.getItem("monlivre_titre") 
    const auteur = sessionStorage.getItem("monlivre_auteur")
    const editeur = sessionStorage.getItem("monlivre_editeur")
    const edition = sessionStorage.getItem("monlivre_edition")
    const urlPhoto = sessionStorage.getItem("monlivre_urlPhoto")
    const urlPhotoValidation = sessionStorage.getItem("monlivre_urlPhoto_statut")
    const barcode = sessionStorage.getItem("monlivre_barcode")
    const statut = sessionStorage.getItem("monlivre_statut")
    const expiration = sessionStorage.getItem("monlivre_expiration")
    const annotations = sessionStorage.getItem("monlivre_annotations")
    const haveInternetCode = sessionStorage.getItem("monlivre_haveInternetCode")
    const idConcession = sessionStorage.getItem("monlivre_idConcession")
    const raisonRefus = sessionStorage.getItem("monlivre_refus")

    const [sousTexteStatut, setsousTexteStatut] = useState("")

    const [prix, setPrix] = useState(sessionStorage.getItem("monlivre_prix"))

    const [mainSpinnerVisible, setmainSpinnerVisible] = useState("d-none")
    const [spinnerVisible, setspinnerVisible] = useState("d-none")


    const [nouveauPrix, setnouveauxPrix] = useState(prix)
    const [erreurNouveauPrix, setErreurNouveauPrix] = useState("")

    const [isOpen, setIsOpen] = useState(false)

    const [renouvelerVisible, setrenouvelerVisible] = useState("d-none")
    const [donnerVisible, setdonnerVisible] = useState("d-none")
    const [retirerVisible, setretirerVisible] = useState("d-none")
    const [donArgentVisible, setdonArgentVisible] = useState("d-none")
    const [supprimerVisible, setsupprimerVisible] = useState("d-none")
    const [btPrixVisible, setbtPrixVisible] = useState("d-none")
    const [expirationVisible, setexpirationVisible] = useState("d-none")
    


    let notes = ""
    let dossierPhoto = ""
    let photo = ""
    let texteStatut = ""
    let codeInternet = ""

    if(statut == "disponible" && urlPhotoValidation != "null")
    {
        dossierPhoto = "upload_photo_concession"
        photo = urlPhotoValidation
    }
    else if(statut == "validation" && urlPhotoValidation != "null")
    {
        dossierPhoto = "upload_photo_reception"
        photo = urlPhotoValidation
    }
    else
    {
        dossierPhoto = "upload_photo_book"
        photo = urlPhoto
    }

    if(statut == "disponible")
        texteStatut = "Disponible pour la vente"

    if(statut == "validation")
        texteStatut = "Livre à déposer à l'AGECTR"

    if(statut == "refuser")
        texteStatut = "Livre refusé"
    
    if(statut == "a_retirer")
        texteStatut = "Livre retiré de la vente"
    
    if(statut == "modification")
        texteStatut = "En attente de validation de la modification"
    
    if(statut == "a_paye")
        texteStatut = "Livre vendu"

    if(statut == "renouveler")
        texteStatut = "Vous devez renouveler la concession"

    if(annotations == "true")
        notes = "Avec annotations"
    else
        notes = "Aucune"

    if(haveInternetCode == "true")
        codeInternet = "Avec code internet"
    else
        codeInternet = "Aucun"
        
    
   

    const handlePrixChange = (event)=>{
        setnouveauxPrix(event.target.value)
        setErreurNouveauPrix("")
    }
        
    const openModal = () => {
        setIsOpen(true);
    }
    
    const hideModal = () => {
        setIsOpen(false);
    }


    const renouvelerConcession = async event =>{
        event.preventDefault()

        if(window.confirm("Voulez-vous renouveler la concession ?") == true)
        {
            let data = new FormData()
            data.append('idConcession', idConcession)

            setmainSpinnerVisible("d-block")

            const result = await connectAPI("renew_concession.php", data)

            setmainSpinnerVisible("d-none")

            if(result.data == null)
            {
                SessionData().resetUserInfos()
                navigate('/connexion')
            }
            else if(result.data.succes == true)
            {
                alert("Concession renouvelée avec succès.")
            }
            else 
            {
                alert("La suppression n'a pas pu se faire. Veuillez réessayer.")
                
            }
        }
    }

    const supprimerConcession = async () => 
    {
        if(window.confirm("Voulez-vous supprimer la concession ?") == true)
        {
            let data = new FormData()
            data.append('idConcession', idConcession)
            data.append('State', statut)

            setmainSpinnerVisible("d-block")
            const result = await connectAPI("delete_concession.php", data)

            setmainSpinnerVisible("d-none")

            if(result.data == null)
            {
                SessionData().resetUserInfos()
                navigate('/connexion')
            }
            else if(result.data.succes == true)
            {
                alert("Suppression réussie")
                navigate('/livres')
            }
            else
            {
                alert("La suppression n'a pas pu se faire. Veuillez réessayer.")
            }
        }
        
    }

    const donnerConcession = async event =>{
        event.preventDefault()

        if(window.confirm("Voulez-vous faire don de la concession à l'AGECTR ?") == true)
        {
            let data = new FormData()
            data.append('idConcession', idConcession)
            setmainSpinnerVisible("d-block")
            
            const result = await connectAPI("give_concession.php", data)
            
            setmainSpinnerVisible("d-none")
        
            if(result.data == null)
            {
                SessionData().resetUserInfos()
                navigate('/connexion')
            }
            else if(result.data.succes == true)
            {
                alert("Concession donnée avec succès.")
                navigate('/livres')
            }
            else
            {
                alert("Le don de concession n'a pas pu se faire. Veuillez réessayer.")
            }
        }
    
    }

    const offrirArgent = async event =>{
        event.preventDefault()

        if(window.confirm("Voulez-vous faire don de l'argent à l'AGE ?") == true)
        {
            let data = new FormData()
            data.append('idHistory', idConcession)

            setmainSpinnerVisible("d-block")
            
            const result = await connectAPI("unpay_concession.php", data)
            
            setmainSpinnerVisible("d-none")
        
            if(result.data == null)
            {
                SessionData().resetUserInfos()
                navigate('/connexion')
            }
            else if(result.data.succes == true)
            {
                alert("Don effectué avec succès.")
                navigate('/livres')
            }
            else
            {
                alert("Le don n'a pas pu se faire. Veuillez réessayer.")
            }
        }
        else
            alert("Vous ne pouvez pas faire de don. La concession doit être vendue.")

    }
    
    const modifierPrix= async (event) => {
        event.preventDefault()

        if(nouveauPrix.trim() === "" ){
            setErreurNouveauPrix('entrez une valeur numérique')
        }
        else
        {
            let p = parseFloat(nouveauPrix.trim())
            
            if(isNaN(p))
            {
                setErreurNouveauPrix('entrez une valeur numérique')
            }
            else
            {
                if(p < 0.50)
                {
                    setErreurNouveauPrix('le prix est trop bas')
                }
                else if(p > 999.99)
                {
                    setErreurNouveauPrix('le prix est trop élevé (max 999.99$)')
                }
                else
                {
                    setspinnerVisible("d-block")
                    let data = new FormData()
                    data.append('idConcession', idConcession)
                    data.append('customerPrice', p.toFixed(2))

                    const result = await connectAPI("update_concession_price.php", data)

                    setspinnerVisible("d-none")

                    if(result.data == null)
                    {
                        SessionData().resetUserInfos()
                        navigate('/connexion')
                    }
                    else if(result.data.succes == true)
                    {
                        setPrix(p.toFixed(2))
                        sessionStorage.setItem("monlivre_prix", p.toFixed(2))
                        hideModal()
                    }
                    else
                    {
                        alert("La modification ne s'est pas faite. Veuillez réessayer")
                    }
                }
            }
        }
    }

    const retirerConcession= async (event) => {
        event.preventDefault()

        if(window.confirm("Voulez-vous retirer la concession de la vente ?") == true)
        {
            setspinnerVisible("d-block")
            let data = new FormData()
            data.append('idConcession', idConcession)

            const result = await connectAPI("remove_concession.php", data)

            setspinnerVisible("d-none")

            if(result.data == null)
            {
                SessionData().resetUserInfos()
                navigate('/connexion')
            }
            else if(result.data.succes == true)
            {
                alert("Concession retirée de la vente avec succès.")
                navigate('/livres')
            }
            else
            {
                alert("Le retrait de la concession n'a pas pu se faire. Veuillez réessayer.")
            }
        }
        
    }

    if(SessionData().getEtatSession() == false)
    {
        SessionData().resetUserInfos()
        navigate('/connexion')

        return null
    }
    else
    {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h4 className={mainSpinnerVisible}><FontAwesomeIcon icon={faSpinner} spin size="2x"  /></h4>
                    </div>
                </div>
                <div className="row my-4">
                    <div className="col-6">
                        <Link to="/livres" className="text-decoration-none">
                            <FontAwesomeIcon icon={faArrowLeft} /> Mes livres
                        </Link>
                    </div>
                    <div className="col-6 text-end">
                        <div class="dropdown">
                            <button class="btn btn-success dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                Option(s)
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li className={renouvelerVisible}><a class="dropdown-item" href="#" onClick={renouvelerConcession}>Renouveler la concession</a></li>
                                <li className={donnerVisible}><a class="dropdown-item" href="#" onClick={donnerConcession}>Donner la concession</a></li>
                                <li className={retirerVisible}><a class="dropdown-item" href="#" onClick={retirerConcession}>Retirer la concession de la vente</a></li>
                                <li className={donArgentVisible}><a class="dropdown-item" href="#" onClick={offrirArgent}>Faire don de l'argent à l'AGE</a></li>
                                <li className={supprimerVisible}><a class="dropdown-item" href="#" onClick={supprimerConcession}>Supprimer la concession</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row bg-white shadow">
                    <div className="col-12 col-sm-4 text-center pt-3">
                        <img src={"http://agectr.com/livreUsage/GlobalAGECTR/" + dossierPhoto + "/"+photo+".png"} className="img-fluid" />
                    </div>
                    <div className="col-12 col-sm-8 p-3">
                    <div className="row my-2 border-bottom">
                            <h6 className="fw-light">Code-barre</h6>
                            <h4>{barcode}</h4>
                        </div>
                        <div className="row my-2 border-bottom">
                            <h6 className="fw-light">Titre</h6>
                            <h4>{titre}</h4>
                        </div>
                        <div className="row my-2 border-bottom">
                            <h6 className="fw-light">Auteur</h6>
                            <h4>{auteur}</h4>
                        </div>
                        <div className="row my-2 border-bottom">
                            <h6 className="fw-light">Edition</h6>
                            <h4>{edition}</h4>
                        </div>
                        <div className="row my-2 border-bottom">
                            <h6 className="fw-light">Editeur</h6>
                            <h4>{editeur}</h4>
                        </div>
                        <div className="row my-2 border-bottom">
                            <div className="col-10">
                                <h6 className="fw-light">Mon prix</h6>
                                <h4>{prix}$</h4>
                            </div>
                            <div className="col-2 text-end align-self-center">
                                <FontAwesomeIcon icon={faPen} size="1x" className={btPrixVisible} onClick={openModal}/>
                            </div>
                        </div>
                        <div className="row my-2 border-bottom">
                            <h6 className="fw-light">Statut</h6>
                            <h4>{texteStatut}</h4>
                            <p>{sousTexteStatut}</p>
                        </div>
                        <div className="row my-2 border-bottom">
                            <h6 className="fw-light">Annotations</h6>
                            <h4>{notes}</h4>
                        </div>
                        <div className="row my-2 border-bottom">
                            <h6 className="fw-light">Code internet</h6>
                            <h4>{codeInternet}</h4>
                        </div>
                        <div className={expirationVisible + " row my-2 border-bottom"}>
                            <h6 className="fw-light">Date d'expiration</h6>
                            <h4>{expiration == "null" ? "Aucune date" : expiration.slice(0,16)}</h4>
                        </div>
                    </div>

                </div>
                
                <Modal show={isOpen} onHide={hideModal}>
                    <Modal.Header>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="text-end">
                                    <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="text-center">
                                    <h3>Modification du prix</h3>
                                </div>
                            </div>
                        </div>
                    </Modal.Header>
                    <ModalBody>
                        <form onSubmit={modifierPrix} noValidate>
                            <div className="col-12 mb-3">
                                <label htmlFor="prix" className="form-label fs-5 ">Entrez le nouveau prix</label>
                                <input type="text" className="form-control" onChange={handlePrixChange} value={nouveauPrix} required aria-label="Prix" aria-describedby="nouveauPrix"/>
                                <div className="text-start form-text col-12 text-danger">{erreurNouveauPrix}</div>
                            </div>
                            <div className=" my-3 text-end col-12">
                                <button type="submit" id="envoyer" className='btn btn-primary text-white'>Modifier</button>
                                <FontAwesomeIcon icon={faSpinner} spin className={spinnerVisible} />
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}