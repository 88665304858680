import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import { connectAPI } from "../helpers/api"
import { livreChoisi } from "../helpers/livreChoisi";
import { useNavigate } from 'react-router-dom';

export const LivresAccueil = props => {

    const navigate=useNavigate()

    const handleLivreClick = (event) =>
    {
        //console.log(props);
        livreChoisi(props)
        navigate('/exemplaires')
    }


    return (
        <div className="col-6 col-md-3" >
            <div className="row">
                <div className="col-8 mx-auto text-center img-hover" onClick={handleLivreClick} id={props.livre.idBook}>
                    <img src={"http://agectr.com/livreUsage/GlobalAGECTR/upload_photo_book/"+props.livre.urlPhoto+".png"} className="img-fluid" style={ {height:'165px'}} id={props.livre.idBook}/>
                </div>
            </div>
            <div className="row py-3">
                <div className="col-8 mx-auto text-center ">
                    <h4>{props.livre.title}</h4>
                </div>
            </div>
        </div>

/* 
        <div className="col">
            <div className="card shadow "  >
                <div className="row g-0">
                    <div className="col-4 ">
                        <img src={"http://agectr.com/livreUsage/GlobalAGECTR/upload_photo_book/"+props.livre.urlPhoto+".png"} className="card-img-top" style={{minHeight:'170px'}, {height:'190px'}}/>
                    </div>
                    <div className="col-8">
                        <div className="card-body">
                            <div className="row">
                                <small className="card-text text-end fst-italic">{props.livre.barcode}</small>
                            </div>
                            <div className="row">
                                <h6 className="card-title">
                                    {props.livre.title}
                                </h6>
                            </div>
                            <div className="row">
                                <small className="card-text">{props.livre.author}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
           */
    )
}

